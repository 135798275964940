@import 'scss/mixins';
@import 'scss/media-queries';
@import 'scss/typography';
@import 'scss/spacing';

$borderRadius: var(--border-radius-sm);
$inputMrgTop: var(--space-2);
$inputMrgBottom: var(--space-5);

$borderColor: var(--field-interaction-standard);
$borderDisabledColor: var(--field-interaction-disabled);
$borderHoverColor: var(--field-interaction-hover);
$borderFocusColor: var(--field-interaction-pressed);
$borderErrorColor: var(--field-interaction-error);
$textColor: var(--field-interaction-text-color);
$textDisabledColor: var(--field-interaction-text-disabled-color);
$placeHolderColor: var(--field-interaction-placeholder-color);
$placeHolderFocusColor: var(--field-interaction-placeholder-pressed-color);
$placeHolderErrorColor: var(--field-interaction-placeholder-error-color);
$placeHolderDisabledColor: var(--field-interaction-placeholder-disabled-color);
$helperTextColor: var(--field-interaction-helper-color);
$helperTextErrorColor: var(--field-interaction-helper-error-color);

$miniSize: 12px;
$smallSize: 16px;
$mediumSize: 24px;

.isolation {
  isolation: isolate;
}

.input {
  $inputRef: &;
  --side-pad: #{var(--space-4)};
  --bottom-mrg: #{var(--space-5)};
  --error-color: #{$borderColor};
  --inputH: 40px;
  --left-icon-size: #{$mediumSize};
  --right-icon-size: #{$mediumSize};

  position: relative;
  margin-top: $inputMrgTop;

  &.large {
    --inputH: 48px;
  }

  &.smallLeftIcon {
    --left-icon-size: #{$smallSize};
  }
  &.smallRightIcon {
    --right-icon-size: #{$smallSize};
  }
  &.miniLeftIcon {
    --left-icon-size: #{$miniSize};
  }
  &.miniRightIcon {
    --right-icon-size: #{$miniSize};
  }

  @mixin withError {
    #{$inputRef}._withError & {
      @content;
    }
  }

  @mixin withValid {
    #{$inputRef}._withValid & {
      @content;
    }
  }

  @mixin inputFocused {
    #{$inputRef}Element:focus ~ & {
      @content;
    }
  }

  @mixin inputHover {
    #{$inputRef}:hover > & {
      @content;
    }
  }

  @mixin inputDisabled {
    #{$inputRef}Element:disabled ~ & {
      @content;
    }
  }

  @mixin withLeftIcon {
    #{$inputRef}._withLeftIcon & {
      @content;
    }
  }

  @mixin withRightIcon {
    #{$inputRef}._withRightIcon & {
      @content;
    }
  }

  &Element {
    z-index: 1;
    position: relative;
    width: 100%;
    height: var(--inputH);
    border: none;
    border-radius: $borderRadius;
    color: $textColor;
    @include body-sm;
    @include padding-x(var(--side-pad));
    @include withLeftIcon {
      padding-left: calc(
        var(--left-icon-size) + var(--side-pad) + var(--space-2)
      ); // icon = 24px + left pad = 16px + pad b/w icon & text = 8px
    }
    @include withRightIcon {
      padding-right: calc(
        var(--right-icon-size) + var(--side-pad) + var(--space-2)
      ); // icon = 24px + left pad = 16px + pad b/w icon & text = 8px
    }

    &::placeholder {
      color: transparent;
    }

    &:disabled {
      color: $textDisabledColor;
      background: var(--neutral-6);
      cursor: not-allowed;

      // for iOS
      opacity: 1;
      -webkit-text-fill-color: $textDisabledColor;
    }
  }

  &Border {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: var(--inputH);
    border: 1px solid $borderColor;
    border-radius: $borderRadius;

    @include inputFocused {
      border-color: $borderFocusColor;
    }

    &.externalRef {
      border-color: $borderFocusColor;
    }

    @include inputHover {
      border-color: $borderHoverColor;
    }

    @include withError {
      border-color: $borderErrorColor;
    }

    @include inputDisabled {
      border-color: $borderDisabledColor;
    }

    &.isRounded {
      border-radius: var(--border-radius-32);
    }
  }

  &Placeholder {
    --x: 0px;
    --y: 0px;
    --s: 1;

    z-index: 2;
    position: absolute;
    height: var(--inputH);
    left: var(--side-pad);
    right: var(--side-pad);
    top: 0;

    text-align: left;
    background: transparent;
    color: $placeHolderColor;
    transform-origin: top left;
    transform: translate(var(--x), var(--y)) scale(var(--s));
    transition: all 0.3s;

    pointer-events: none;
    @include body-sm;
    @include overflowedText;
    line-height: var(--inputH);

    #{$inputRef}Element:-webkit-autofill ~ &:not(.noFloat),
    #{$inputRef}Element:not(:placeholder-shown) ~ &:not(.noFloat),
    #{$inputRef}Element:focus ~ &:not(.noFloat),
    &.externalRef {
      // externalRef -> some other component like phoneInput (3rd party) wants the same custom input style, use this class
      $height: 16px;
      height: $height;
      $centerPosition: calc(#{$height} / 2);
      $deductBorder: calc(#{$centerPosition} - 3px);
      --y: calc(#{$deductBorder} * -1);
      --s: 0.7;
      margin-right: unset !important;
      right: unset !important;
      max-width: calc(100% - 24px);
      @include padding-x(var(--space-1));
      @include margin-x(calc(var(--space-1) * -1));
      line-height: $height;
      color: $placeHolderFocusColor;
      background: var(--primary-variant-1);
      transition: all 0.3s;
    }

    &.noFloat {
      transition: unset;
      color: $placeHolderColor !important;
    }
    #{$inputRef}Element:-webkit-autofill ~ &.noFloat,
    #{$inputRef}Element:not(:placeholder-shown) ~ &.noFloat {
      background-color: transparent;
      color: transparent !important;
    }

    @include inputFocused {
      color: $placeHolderFocusColor;
    }

    @include inputDisabled {
      color: $placeHolderDisabledColor;
    }

    @include withError {
      color: $placeHolderErrorColor !important;
    }

    @include withLeftIcon {
      margin-left: calc(var(--left-icon-size) + var(--space-2));
    }

    @include withRightIcon {
      margin-right: calc(var(--right-icon-size) + var(--space-2));
      right: 1px;
    }
  }

  &Description {
    text-align: left;
    padding: var(--space-1) var(--side-pad) 0;
    color: $helperTextColor;
    @include text-sm;

    &::before {
      content: '';
      display: inline-block;
    }

    @include withError {
      color: $helperTextErrorColor;
    }

    @include withValid {
      color: $helperTextColor;
    }

    &._withWarning {
      color: var(--text-color-grey-1);
    }

    &.empty {
      height: 8px;
      width: 100%;

      @include until-phone {
        height: 0;
        padding: 0;
      }
    }
  }

  &LeftIcon {
    left: var(--side-pad);
    $height: var(--left-icon-size);
    --diff: calc(var(--inputH) - var(--left-icon-size));
  }

  &RightIcon {
    right: var(--side-pad);
    $height: var(--right-icon-size);
    --diff: calc(var(--inputH) - var(--right-icon-size));
  }
  &LeftIcon,
  &RightIcon {
    position: absolute;
    z-index: 2;
    top: calc(var(--diff) / 2);
    display: flex;
    align-items: center;

    &.mini {
      $diff: calc(var(--inputH) - 12px);
      top: calc(var(--diff) / 2);
    }
    &.small {
      $diff: calc(var(--inputH) - 16px);
      top: calc(var(--diff) / 2);
    }
    &.large {
      $diff: calc(var(--inputH) - 32px);
      top: calc(var(--diff) / 2);
    }
  }

  &EndAdornment {
    position: absolute;
    top: 0;
    bottom: 0;
    right: var(--side-pad);
    display: flex;
    align-items: center;
  }
}

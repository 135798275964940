@import 'scss/media-queries';
@import 'scss/mixins';
@import 'scss/typography';

.chip {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  margin-right: var(--space-2);
  margin-bottom: var(--space-2);
  white-space: nowrap;
  max-width: 100%;
  height: var(--chip-height);
  text-overflow: ellipsis;
  overflow: hidden;

  --border-thickness: 1px;

  color: var(--font-color);
  background-color: var(--background-color);
  padding: var(--chip-v-padding) var(--chip-h-padding);
  border: var(--border-thickness) solid var(--border-color);

  .truncateLongText {
    max-width: 340px;
    display: flex;
    align-items: center;

    @include until-phone {
      max-width: 600px;
    }

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .truncate {
    max-width: 100%;
    flex: 1;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus-visible:not(&:hover, &.selected) {
    box-shadow: 0 0 0 2px var(--primary-variant-1), 0 0 0 4px var(--neutral-1);

    &.dark-ghost {
      box-shadow: 0 0 0 2px var(--neutral-1);
    }

    &.light-ghost-bold {
      --background-color: var(--neutral-5);
    }
  }

  &.light {
    --background-color: var(--white);
    --border-color: var(--neutral-3);
    --font-color: var(--text-color-grey-2);

    svg path {
      fill: var(--neutral-2);
    }

    &:hover,
    &.selected {
      --font-color: var(--text-color-grey-1);
      --border-color: var(--black-interaction-pressed);
    }
    &:disabled {
      --border-color: transparent;
      --font-color: var(--neutral-9);
      --background-color: var(--neutral-6);
    }
    &.selected {
      --background-color: var(--neutral-5);
    }

    &:hover,
    &.selected {
      svg path {
        fill: var(--black-interaction-hover);
      }
    }
  }

  &.light-ghost {
    --border-color: transparent;
    --border-thickness: 0px;
    --background-color: transparent;
    --font-color: var(--text-color-grey-2);

    svg path {
      fill: var(--text-color-grey-2);
    }

    &:hover,
    &.selected {
      --font-color: var(--text-color-grey-1);
      --border-color: var(--black-interaction-pressed);

      svg path {
        fill: var(--black-interaction-pressed);
      }
    }
    &:disabled {
      --font-color: var(--neutral-9);
    }
    &.selected {
      --background-color: var(--neutral-5);
    }
  }

  &.light-ghost-bold {
    @extend .light-ghost;

    font-weight: var(--font-weight-bold);
    --background-color: var(--neutral-5);

    &:hover {
      --font-color: var(--text-color-grey-1);
      --border-color: transparent;
    }

    &:disabled {
      --font-color: var(--neutral-9);
    }

    &.selected {
      --font-color: var(--white);
      --background-color: var(--black-interaction-hover);

      svg path {
        fill: var(--white);
      }
    }

    &:active {
      background-color: var(--neutral-5);
      box-shadow: 0 0 0 2px var(--neutral-4);
    }
  }

  &.light-transparent {
    --background-color: transparent;
    --border-color: var(--neutral-4);
    --font-color: var(--neutral-6);

    svg path {
      fill: var(--neutral-6);
    }

    &:hover,
    &.selected {
      --background-color: rgba(255, 255, 255, 0.1);
      --font-color: var(--white);
    }
    &:disabled {
      --background-color: var(--neutral-6);
    }

    &:hover,
    &.selected {
      svg path {
        fill: var(--white);
      }
    }
  }

  &.dark-ghost-bold {
    @extend .light-ghost-bold;
    --background-color: var(--white);
  }

  &.dark {
    --border-color: var(--neutral-3);
    --background-color: var(--white);
    --font-color: var(--text-color-grey-2);

    &:hover {
      --font-color: var(--text-color-grey-1);
      --border-color: var(--black-interaction-pressed);
    }
    &:disabled {
      --border-color: transparent;
      --font-color: var(--neutral-9);
      --background-color: var(--neutral-6);
    }
    &.selected {
      --font-color: var(--white);
      --background-color: var(--neutral-1);
      --border-color: var(--black-interaction-pressed);
    }
  }

  &.dark-ghost {
    --font-color: var(--white);
    --background-color: transparent;
    --border-color: transparent;

    &:hover {
      --border-color: var(--neutral-3);

      @include until-phone {
        --border-color: transparent;
      }
    }

    &:disabled {
      --font-color: var(--neutral-9);
    }
    &.selected {
      --background-color: var(--neutral-1);
      --border-color: var(--black-interaction-pressed);
    }
  }

  svg.delete {
    margin-left: var(--space-1);
  }

  &.chip-xsmall {
    --border-radius: var(--border-radius-lg);
    --chip-h-padding: var(--space-2);
    --chip-v-padding: var(--space-1);
    @include text-sm;
  }

  &.chip-small {
    --border-radius: var(--border-radius-lg);
    --chip-h-padding: var(--space-2);
    --chip-v-padding: var(--space-1);
    --chip-height: 28px;
    @include label-sm;
  }

  &.chip-medium {
    --border-radius: var(--border-radius-24);
    --chip-h-padding: var(--space-3);
    --chip-v-padding: var(--space-2);
    --chip-height: 36px;
    @include label-sm;
  }

  &.chip-semi-large {
    --chip-h-padding: var(--space-3);
    --chip-v-padding: var(--space-2);
    --border-radius: var(--border-radius-24);
    --chip-height: 40px;
    @include label-md;

    &.light-ghost-bold,
    &.dark-ghost-bold {
      --chip-h-padding: var(--space-4);
      --chip-v-padding: var(--space-3);
    }
  }

  &.chip-large {
    --chip-h-padding: var(--space-3);
    --chip-v-padding: var(--space-2);
    --border-radius: var(--border-radius-24);
    --chip-height: 48px;
    @include label-md;

    &.light-ghost-bold,
    &.dark-ghost-bold,
    &.light-transparent {
      --chip-h-padding: var(--space-4);
      --chip-v-padding: var(--space-3);
    }
  }
}

.chipsContainer {
  display: flex;
  align-items: center;
  width: 100%;

  .chipsContent {
    overflow: scroll;
    display: flex;
    position: relative;
    padding: var(--space-2) 0;

    @include until-phone {
      margin-left: calc(var(--page-side-space) * -1);
      margin-right: calc(var(--page-side-space) * -1);
      padding: 0 var(--page-side-space);

      .chip:first-child {
        margin-left: var(--page-side-space);
      }
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .sliderArrowContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    width: 114px;

    &.previous {
      left: 0;
      justify-content: flex-start;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 93.75%);
    }

    &.next {
      right: 0;
      justify-content: flex-end;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 93.75%);
    }

    .sliderArrow {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--white);
      padding: 7px;
      border: 1px solid var(--black-interaction-standard);
      border-radius: var(--border-radius-lg);

      &.withoutBorder {
        padding: 14px 7px;
        border: none;
        border-radius: 0px;
        color: var(--neutral-2);
      }
    }
  }

  &.light-ghost .sliderArrow {
    color: var(--primary-variant-2);
  }

  .hidden {
    visibility: hidden;
  }

  .showMore {
    display: flex;
    justify-content: center;
  }
}

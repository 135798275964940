.container {
  display: grid;
  gap: var(--space-4);
  justify-items: start;

  .list {
    display: grid;
    gap: var(--space-5);
    width: 100%;

    &.lastPage {
      margin-bottom: var(--space-6);
    }
  }
}

@import 'scss/media-queries';

.backdrop {
  --overlay-background: rgba(0, 0, 0, 0.5);
  --responsive-modal-margin-top: 12vh; // have component dependancy. will be updated via js from component

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0;
  z-index: var(--modal-zindex);
  background-color: var(--overlay-background);
  transition: opacity 150ms ease-in-out;
  display: flex;
  justify-content: center;

  &.showOnTop {
    z-index: var(--overlay-modal-zindex);
  }

  .content {
    position: relative;
    margin: 12vh auto auto;
    width: max-content;
    opacity: 0;

    .closeBtn {
      position: absolute;
      top: 20px;
      right: 20px;
      opacity: 0.6;
      z-index: 2;

      &:hover {
        opacity: 1;
      }

      @include until-phone {
        opacity: 1;
      }
    }

    &.fade {
      transition: opacity 200ms ease-in-out;
    }

    &.slideUp {
      transform: translateY(100%);
      transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
    }
  }

  &.active {
    opacity: 1;

    .content {
      transform: none;
      opacity: 1;
      z-index: 2001;
    }
  }

  &:not(.fullScreen) .content {
    --horizontal-margin: var(--space-4);

    margin: var(--responsive-modal-margin-top, 12vh) var(--horizontal-margin) auto;
    max-width: calc(100% - var(--horizontal-margin) * 2);
  }

  &.fullScreen {
    .content {
      margin: auto;
      width: 100%;
    }
  }
}

@keyframes beating {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.border-white {
  path:nth-child(2) {
    fill: white;
  }
}

.heart-fill {
  button.selected,
  &,
  &.selected {
    animation: beating 300ms linear;
    path:nth-child(1) {
      fill: var(--red);
      fill-opacity: 1;
    }
    path:nth-child(2) {
      fill: var(--red);
    }
  }

  button.selected.border-white,
  &.selected.border-white {
    @extend .border-white;
    path:nth-child(2) {
      fill: white;
    }
  }
}

.heart {
  path:nth-child(1) {
    fill: transparent !important;
  }

  .border-white {
    @extend .border-white;
  }

  &.grey-fill-light {
    path:nth-child(1) {
      fill: black !important;
      fill-opacity: 0.5;
    }
  }

  button.animate &,
  &.animate {
    animation: beating 300ms linear;
  }
}

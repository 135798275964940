@import './media-queries';

@mixin get-header-css($fs, $ls, $lh) {
  font-size: $fs;
  letter-spacing: $ls;
  line-height: $lh;
  font-weight: var(--header-font-weight);
}

@mixin header-display {
  @include get-header-css(
    var(--header-display),
    var(--header-display-letter-space),
    var(--header-display-line-height)
  );
}
.header-display {
  @include header-display;
}

@mixin header-1 {
  @include get-header-css(var(--header-1), var(--header-1-letter-space), var(--header-1-line-height));
}
.header-1 {
  @include header-1;
}

@mixin header-2 {
  @include get-header-css(var(--header-2), var(--header-2-letter-space), var(--header-2-line-height));
}
.header-2 {
  @include header-2;
}

@mixin header-3 {
  @include get-header-css(var(--header-3), var(--header-3-letter-space), var(--header-3-line-height));
}
@mixin header-3-bold {
  @include header-3;
  font-weight: var(--font-weight-bold);
}
.header-3 {
  @include header-3;
}

@mixin header-4 {
  @include get-header-css(var(--header-4), var(--header-4-letter-space), var(--header-4-line-height));
}
@mixin header-4-bold {
  @include header-4;
  font-weight: var(--font-weight-bold);
}

.header-4 {
  @include header-4;
}

@mixin header-5 {
  @include get-header-css(var(--header-5), var(--header-5-letter-space), var(--header-5-line-height));
}
@mixin header-5-bold {
  @include header-5;
  font-weight: var(--font-weight-bold);
}
.header-5 {
  @include header-5;
}

@mixin header-6 {
  @include get-header-css(var(--header-6), var(--header-6-letter-space), var(--header-6-line-height));
}
@mixin header-6-bold {
  @include header-6;
  font-weight: var(--font-weight-bold);
}
.header-6 {
  @include header-6;
}

.text-dark {
  color: var(--text-color);
}

.text-tertiary {
  color: var(--text-color-tertiary);
}

.text-grey-1 {
  color: var(--text-color-grey-1);
}

.text-grey-2 {
  color: var(--text-color-grey-2);
}

.text-grey-3 {
  color: var(--text-color-grey-3);
}

.text-grey-4 {
  color: var(--text-color-grey-4);
}

.text-grey-5 {
  color: var(--text-color-grey-5);
}

.text-white {
  color: var(--text-white);
}

.text-success {
  color: var(--text-color-dark-green);
}

.text-warning {
  color: var(--text-color-warning);
}

.text-error {
  color: var(--text-color-error);
}

.text-processing {
  color: var(--text-color-processing);
}

.text-info {
  color: var(--info-text);
}

//body
@mixin body-sm {
  font-size: var(--body-text-sm);
  letter-spacing: -0.001em;
  line-height: calc(var(--body-text-sm) * 1.6);
}
@mixin body-sm-bold {
  @include body-sm;
  font-weight: var(--font-weight-bold);
}
.body-sm {
  @include body-sm;
}

@mixin body-md {
  font-size: var(--body-text-md);
  letter-spacing: -0.0009em;
  line-height: calc(var(--body-text-md) * 1.5);
}
@mixin body-md-bold {
  @include body-md;
  font-weight: var(--font-weight-bold);
}
.body-md {
  @include body-md;
}

// label
@mixin label-sm {
  font-size: var(--label-text-sm);
  letter-spacing: 0;
  line-height: calc(var(--label-text-sm) * 1.43);
}
@mixin label-sm-bold {
  @include label-sm;
  font-weight: var(--font-weight-bold);
}
@mixin label-sm-ex-bold {
  @include label-sm;
  font-weight: var(--font-weight-extra-bold);
}
.label-sm {
  @include label-sm;
}

@mixin label-md {
  font-size: var(--label-text-md);
  letter-spacing: -0.0009em;
  line-height: calc(var(--label-text-md) * 1.5);
}
@mixin label-md-bold {
  @include label-md;
  font-weight: var(--font-weight-bold);
}
@mixin label-md-ex-bold {
  @include label-md;
  font-weight: var(--font-weight-extra-bold);
}
.label-md {
  @include label-md;
}

@mixin label-lg {
  font-size: var(--label-text-lg);
  letter-spacing: -0.001em;
  line-height: calc(var(--label-text-lg) * 1.33);
}
@mixin label-lg-bold {
  @include label-lg;
  font-weight: var(--font-weight-bold);
}
@mixin label-lg-ex-bold {
  @include label-lg;
  font-weight: var(--font-weight-extra-bold);
}
.label-lg {
  @include label-lg;
}

@mixin text-xs {
  font-size: var(--text-xs);
  letter-spacing: 0;
  line-height: calc(var(--text-xs) * 1.3);
}
@mixin text-xs-bold {
  @include text-xs;
  font-weight: var(--font-weight-bold);
}
.text-xs {
  @include text-xs;
}

@mixin text-sm {
  font-size: var(--text-sm);
  letter-spacing: 0;
  line-height: calc(var(--text-sm) * 1.33);
}
@mixin text-sm-bold {
  @include text-sm;
  font-weight: var(--font-weight-bold);
}
.text-sm {
  @include text-sm;
}

.regular-bold {
  font-weight: var(--font-weight-regular);
}

.bold {
  font-weight: var(--font-weight-bold);
}

.extra-bold {
  font-weight: var(--font-weight-extra-bold);
}

@import 'scss/media-queries';
@import 'scss/typography';
@import 'scss/mixins';

.footer {
  width: 100vw;
  background: var(--primary-variant-2);
  border-top: 5px solid var(--primary);
  padding-top: var(--space-5);
  padding-bottom: var(--space-6);
  margin-top: var(--space-16);
  position: relative;

  @include until-tablet {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
    margin-top: var(--space-12);
  }

  a:hover {
    color: var(--text-white);
  }

  .logo {
    display: flex;
    align-items: center;

    @include until-tablet {
      justify-content: center;
      margin-bottom: var(--space-5);
    }
  }

  .responsiveLabel {
    @include mediaMaxWidth(1200px) {
      display: none;
    }
    @include until-phone() {
      display: block;
    }
  }

  .langCurrencyContent {
    padding: 0 !important;
    margin-bottom: var(--space-2) !important;

    @include until-tablet {
      transform: translateX(25%);
    }
  }

  .groupOne {
    display: grid;
    grid-template-columns: 186px 1fr;
    padding-bottom: var(--space-1);
    border-bottom: 1px solid var(--neutral-2);

    @include until-tablet {
      grid-template-columns: 1fr;
      align-items: center;
      padding-bottom: var(--space-6);
    }

    .menus {
      position: relative;
      display: flex;
      justify-content: right;
      flex-wrap: wrap;

      @include until-tablet {
        justify-content: center;
      }

      > span {
        cursor: pointer;
      }

      > span,
      > div {
        margin-right: var(--space-2);
        @include until-tablet {
          margin-right: 0;
        }
      }
    }
  }

  .groupTwo {
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    margin-top: var(--space-4);

    @include until-tablet {
      grid-template-columns: 1fr;
      margin-top: var(--space-6);
    }

    .termsAndPolicies {
      display: flex;
      align-items: center;
      white-space: nowrap;
      @include text-xs;
      margin-bottom: 0;

      @include until-tablet {
        @include text-sm;
        grid-row: 2/3;
        justify-content: center;
        margin: var(--space-3) 0 var(--space-4) 0;
      }

      a + a {
        &::before {
          content: '·';
          margin-left: var(--space-1);
          margin-right: var(--space-1);
        }
      }
    }

    .currencyLangSocialLogos {
      display: flex;
      justify-content: right;
      align-items: center;

      @include until-tablet {
        display: block;
        grid-row: 1/2;
        justify-content: center;
        align-items: unset;
      }

      .currencyLang {
        display: none;

        @include until-tablet {
          display: flex;
          justify-content: center;
        }
      }
    }

    .downloadBtns {
      display: flex;
      align-items: center;
      gap: var(--space-4);
      margin-right: calc(var(--space-4) - var(--space-1-half));
      margin-left: var(--space-6);

      a {
        display: flex;
      }

      @include until-tablet {
        justify-content: center;
        margin: 0;
        margin-top: var(--space-6);
        margin-bottom: calc(var(--space-4) - var(--space-1-half));

        a {
          display: inline-block;
        }
      }
    }

    .socialLogos {
      display: flex;
      color: var(--text-white);

      @include until-tablet {
        justify-content: center;
      }

      button {
        height: auto;
      }

      svg {
        display: block;
        @include size(16px);

        @include until-tablet {
          @include size(24px);
        }
      }
    }

    .copyRightText {
      @include text-xs;
      margin-right: var(--space-6);

      @include until-tablet {
        @include text-sm;
        text-align: center;
      }
    }
  }
}

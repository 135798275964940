@import 'scss/media-queries';
@import 'scss/mixins';
@import 'scss/typography';

.initiDropdown {
  display: grid;
}

.tags {
  display: flex;
  gap: var(--space-2);
  margin: var(--space-4) var(--space-4) var(--space-3);
  overflow-x: scroll;
  padding: 1px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    height: 0; /* Remove scrollbar space */
    display: none;
  }

  .tag {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: var(--space-2) var(--space-3);
    border: 0.5px solid var(--grey-5-interaction-border-color);
    border-radius: var(--border-radius-xl);
    @include label-sm();
    color: var(--text-color-grey-2);
    white-space: nowrap;

    &:hover {
      color: var(--primary-variant-2);
      border: 0.5px solid var(--primary-variant-2);
      box-shadow: 0 0 0 0.5px var(--primary-variant-2);
    }
  }

  @include until-phone {
    margin: var(--space-3) var(--space-4) var(--space-2);
  }
}

.dropdownHeader {
  text-align: left;
  @include text-sm-bold;
  padding: var(--space-2) var(--space-4);
  background-color: var(--neutral-6);
  color: var(--text-color-grey-2);
}

.recentSearches,
.trendingSearches {
  display: grid;
}

// only trending search exceeds he view port height
// move padding to dropdown if all esults are overflowing
.trendingSearches .results {
  margin-bottom: var(--space-2);

  @include until-phone {
    padding-bottom: var(--space-24);
    margin-bottom: var(--space-4);
  }
}

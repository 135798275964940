@import 'scss/mixins';
@import 'scss/media-queries';
@import 'scss/typography';

.headerInputContainer {
  --input-height: 40px;
  --input-width: 100%;
  --input-text-color: var(--text-white);
  --input-border: 1px solid var(--neutral-2);
  --input-border-radius: calc(var(--input-height) / 2);
  --input-placeholder-color: var(--text-white);
  --input-background-color: var(--neutral-1);
  --input-padding-left: var(--space-12);
  --input-cursor-behaviour: pointer;

  --search-icon-text-color: var(--text-white);
  --search-icon-left-position: var(--space-4);
  --transition-delay: 150ms;

  height: var(--input-height);
  width: var(--input-width);
  overflow: hidden;
  border: var(--input-border);
  border-radius: var(--input-border-radius);
  background-color: var(--input-background-color);
  color: var(--input-text-color);

  display: grid;
  grid-template-columns: 1fr;
  align-content: center;

  transition: width 150ms ease-in-out var(--transition-delay),
    background 300ms ease-in-out var(--transition-delay);

  &:focus-within,
  &.with-value {
    --input-border: 1px solid var(--primary-variant-2);
    --input-background-color: var(--primary-variant-1);
    --input-placeholder-color: var(--text-color-grey-1);
    --input-text-color: var(--text-color-grey-1);

    --search-icon-text-color: var(--text-color);
  }

  &:hover {
    --input-border: 1px solid var(--neutral-3);
  }

  &.with-value {
    transition: width 200ms ease-in-out 150ms; // give some delay so that user has time to click on item
    grid-template-columns: auto max-content;
  }

  &:focus-within,
  &:focus-within.with-value {
    --transition-delay: 0ms;
  }

  .searchIcon {
    top: 50%;
    position: absolute;
    color: var(--search-icon-text-color) !important;
    left: var(--search-icon-left-position);
    transform: translateY(-50%);
    pointer-events: none;
    transition: all 150ms ease-in-out;
  }

  .clearButton {
    align-self: center;
    margin-right: var(--space-2);
  }

  input {
    @include label-sm;
    font-size: var(--label-text-sm) !important;

    cursor: var(--input-cursor-behaviour);
    text-transform: initial;
    padding-left: var(--input-padding-left);
    color: var(--input-text-color);
    width: 100%;
    height: var(--input-height);

    &::-webkit-input-placeholder {
      color: var(--input-placeholder-color);
    }
    &::-moz-placeholder {
      color: var(--input-placeholder-color);
    }
    &:-ms-input-placeholder {
      color: var(--input-placeholder-color);
    }
    &:-moz-placeholder {
      color: var(--input-placeholder-color);
    }
  }
}

.asSearchIconContainer {
  width: 100%;
  display: flex;
  padding-right: var(--space-2);

  svg {
    margin-left: auto;
  }
}

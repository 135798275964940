.loaderContainer {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  left: var(--space-4);

  &.isFirstFold,
  &.isIcon {
    top: unset;
    position: relative;
    transform: unset;
    left: unset;
  }

  &.isIcon {
    margin-left: auto;
  }

  .loader {
    width: 24px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: radial-gradient(farthest-side, var(--neutral-4) 94%, #0000) top/4px 4px no-repeat,
      conic-gradient(#0000 30%, var(--neutral-4));
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
    animation: rotate 1s infinite linear;
  }
  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
}

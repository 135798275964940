@import 'scss/typography';
@import 'scss/media-queries';

.recentlyViewed {
  padding-top: var(--space-2);
  padding-bottom: var(--space-8);

  &Title {
    @include label-md;
    padding: 0 var(--space-6);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--space-2);
  }

  @include until-phone {
    &Title {
      padding: 0 var(--space-4);
    }
  }
}

@use 'sass:math';
@import './media-queries';

$space-multipliers: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 24, 28, 30;

@mixin generateMarginUtilClasses($number) {
  $margin-mappings: (
    m: margin,
    ml: margin-left,
    mr: margin-right,
    mb: margin-bottom,
    mt: margin-top,
  );

  @if $number == 0 {
    .marginless {
      margin: var(--space-#{$number});
    }
  } @else {
    .mx-#{$number} {
      @extend .mr-#{$number};
      @extend .ml-#{$number};
    }

    .my-#{$number} {
      @extend .mt-#{$number};
      @extend .mb-#{$number};
    }

    @each $prefix, $property in $margin-mappings {
      .#{$prefix}-#{$number} {
        #{$property}: var(--space-#{$number});
      }
    }
  }
}

@mixin generatePaddingUtilClasses($number) {
  $padding-mappings: (
    p: padding,
    pl: padding-left,
    pr: padding-right,
    pb: padding-bottom,
    pt: padding-top,
  );

  @if $number == 0 {
    .paddingless {
      padding: var(--space-#{$number});
    }
  } @else {
    .px-#{$number} {
      @extend .pr-#{$number};
      @extend .pl-#{$number};
    }

    .py-#{$number} {
      @extend .pt-#{$number};
      @extend .pb-#{$number};
    }

    @each $prefix, $property in $padding-mappings {
      .#{$prefix}-#{$number} {
        #{$property}: var(--space-#{$number});
      }
    }
  }
}

// generate util class names
@each $number in $space-multipliers {
  @include generateMarginUtilClasses($number);
  @include generatePaddingUtilClasses($number);
}

/* COLUMN START */
$gutter: var(--gutter);
$widthPerColumn: math.div(100%, 12);

@mixin row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: calc(#{$gutter} * -1);
  margin-left: calc(#{$gutter} * -1);
}
.row {
  @include row;
}

@mixin col($width) {
  position: relative;
  width: 100%;
  padding-left: #{$gutter};
  padding-right: #{$gutter};
  -webkit-box-flex: 0;
  -ms-flex: 0 0 #{$width};
  flex: 0 0 #{$width};
  max-width: #{$width};
}

@mixin offset-0 {
  $width: calc(#{$widthPerColumn} * 0);
  margin-left: #{$width};
}
.offset-0 {
  @include offset-0;
}

@mixin offset-3 {
  $width: calc(#{$widthPerColumn} * 3);
  margin-left: #{$width};
}
.offset-3 {
  @include offset-3;
}

@mixin col-2 {
  $width: calc(#{$widthPerColumn} * 2);
  @include col($width);
}
.col-2 {
  @include col-2;
}

@mixin col-3 {
  $width: calc(#{$widthPerColumn} * 3);
  @include col($width);
}
.col-3 {
  @include col-3;
}

@mixin col-4 {
  $width: calc(#{$widthPerColumn} * 4);
  @include col($width);
}
.col-4 {
  @include col-4;
}

@mixin col-6 {
  $width: calc(#{$widthPerColumn} * 6);
  @include col($width);
}

@mixin col-7 {
  $width: calc(#{$widthPerColumn} * 7);
  @include col($width);
}
.col-7 {
  @include col-7;
}

@mixin col-8 {
  $width: calc(#{$widthPerColumn} * 8);
  @include col($width);
}
.col-8 {
  @include col-8;
}

@mixin col-9 {
  $width: calc(#{$widthPerColumn} * 9);
  @include col($width);
}
.col-9 {
  @include col-9;
}

@mixin col-12 {
  $width: calc(#{$widthPerColumn} * 12);
  @include col($width);
}
.col-12 {
  @include col-12;
}
/* COLUMN END */

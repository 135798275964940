@import 'scss/mixins';

.loadingElement {
  border-radius: var(--border-radius-sm);
  background: var(--gradient-loading-bg);
  background-size: 500% 100%;
  @include animateBg();
  box-shadow: 0 1px 7px #ddd;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.loaded {
    display: none;
  }
}

.backgroundImage {
  overflow: hidden;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(180deg, var(--neutral-1) 30.17%, rgba(51, 51, 51, 0.1) 70.18%);

    /* if backdrop support: very transparent and blurred */
    @supports ((-webkit-filter: none) or (filter: none)) {
      background: var(--background-image-url, 'unset');
      background-size: cover;
      background-repeat: no-repeat;
      filter: blur(40px);
    }
  }
}

.imageContainer {
  height: 100%;

  &.hasBackgroundImage {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .body {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
}

.img {
  display: block;

  &.coveredImage {
    min-width: 100%;
    height: auto;
  }

  &.smartFill {
    width: auto;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.imageLazyLoadContainer {
  width: 100%;
  height: 100%;
}

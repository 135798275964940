@import 'scss/media-queries';

.notFound {
  text-align: center;
  margin-top: var(--space-8);

  svg {
    display: block;
    margin: 0 auto var(--space-4) auto;
    width: 168px;
    height: 200px;

    @include until-phone {
      width: 125px;
      height: 148px;
    }
  }
}

@import 'scss/typography';
@import 'scss/mixins';

.toast {
  --primary-color: var(--cancel);
  --secondary-color: var(--cancel-light);

  position: fixed;
  top: calc(var(--header-height) + var(--notification-height));
  left: 50%;
  align-items: center;
  margin: var(--space-2);
  background: var(--secondary-color);
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  z-index: var(--toast-zindex);
  transform: translateX(-50%);
  @include fadeIn(0.5s);

  @include until-phone() {
    left: 0;
    right: 0;
    transform: translateX(0);
  }

  &.error {
    --primary-color: var(--error);
    --secondary-color: var(--error-light);
  }

  &.success {
    --primary-color: var(--success);
    --secondary-color: var(--success-light);
  }

  &.success,
  &.error {
    --content-text-color: var(--text-color);
  }

  .toastContainer {
    padding: var(--space-4) var(--space-4) var(--space-4) var(--space-3);
    display: grid;
    background-color: var(--secondary-color);
    grid-template-columns: auto 1fr auto;
    grid-column-gap: var(--space-2);
    width: 100%;
    border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
    align-items: center;

    &::before {
      content: '';
      z-index: 0;
      width: 4px;
      background-color: var(--primary-color);
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .closeIcon {
    display: flex;
    align-items: center;
  }

  &Icon {
    color: var(--primary-color) !important;
  }
}

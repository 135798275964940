@import 'scss/mixins';
@import 'scss/typography';
@import 'scss/media-queries';

.container {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;

  .title {
    @include header-4;
    text-align: center;
    margin-bottom: var(--space-10);
    color: var(--text-color);

    @include until-phone {
      @include header-3;
    }
  }

  .content {
    margin-top: var(--space-20);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: var(--space-2);

    .illustration {
      margin-bottom: var(--space-8);
    }

    .buttonContainer {
      min-width: 240px;
    }

    .description {
      color: var(--text-color-grey-1);
      @include body-sm;
      margin-bottom: var(--space-6);
    }
  }
}

@import 'scss/mixins';

.headerSearchAutocomplete,
.searchAutocomplete {
  position: relative;
  text-transform: none;

  // for headerSearchAutocomplete this will use the fallback css variable
  // and searchAutocomplete will make use of the css variable mentioned under .searchAutocomplete scope
  .dropdown {
    border-radius: var(--dropdown-border-radius, var(--border-radius-md));
    top: calc(100% + var(--dropdown-margin-top, var(--space-1)));
    right: 0;
    left: 0;
    box-shadow: var(--dropdown-box-shadow, var(--shadow-bottom-2));
    position: absolute;
    z-index: 100;
    background-color: var(--white);
    overflow: auto;
    max-height: calc(100dvh - var(--input-height) - (var(--dropdown-margin-top) * 2));

    @include from-phone {
      max-height: 532px;
    }
    // spacing adjustments for search modal
    @include until-phone {
      --dropdown-margin-top: var(--space-3);

      min-width: 100vw;
      left: calc(-1 * var(--space-4));

      &.gplp {
        // Adjust result position on mobile view for gplp page
        left: calc(-1 * (var(--space-12)));
      }
    }
  }
}

.headerSearchAutocomplete {
  --input-min-width: 280px;
  --input-max-width: 440px;

  @include until-phone {
    --input-min-width: unset;
    --input-max-width: unset;
  }

  min-width: var(--input-min-width);
  max-width: var(--input-max-width);
}

.searchAutocomplete {
  --input-height: 40px;
  --input-border: 1px solid var(--neutral-3);
  --input-border-radius: var(--border-radius-md);
  --input-focus-border: #000;
  --input-left-padding: var(--space-4);
  --input-right-padding: var(--space-4);
  --input-background-color: unset;
  --input-placeholder-color: inherit;
  --input-placeholder-font-size: var(--label-text-md);
  --input-text-color: var(--text-color-grey-1);

  --dropdown-border-radius: var(--border-radius-md);
  --dropdown-margin-top: var(--space-1);
  --dropdown-box-shadow: var(--shadow-bottom-2);

  --submit-button-x-padding: var(--space-4);
  --submit-button-y-padding: 0px;
  --submit-button-border-radius: 0px;
  --clear-button-x-padding: var(--space-2);

  --search-icon-left: var(--space-4);

  --grid-columns: auto;

  &.is-large {
    --input-height: 48px;
    --input-left-padding: var(--space-6);
    --input-right-padding: var(--space-2);
    --submit-button-x-padding: var(--space-6);
  }

  &.is-x-large {
    --input-height: 64px;
  }

  &.variant-white-rounded {
    --input-background-color: var(--white);
    --input-placeholder-color: var(--field-interaction-placeholder-color);

    --input-height: 60px;
    --input-left-padding: var(--space-6);
    --input-right-padding: var(--space-2);
    --submit-button-x-padding: var(--space-7);
    --input-border-radius: calc(var(--input-height) / 2);
    --submit-button-border-radius: calc(var(--input-height) / 2);

    &.is-medium {
      --input-height: 40px;
      --input-left-padding: var(--space-4);
      --submit-button-x-padding: var(--space-3);
      --submit-button-x-padding: var(--space-4);
    }
  }

  &.variant-gplp-rounded {
    --submit-button-border-radius: calc(var(--input-height) / 2);
    --input-border: 1px solid var(--p-gray-color-5);
    --input-border-radius: calc(var(--input-height) / 2);
    --input-left-padding: var(--space-4);
    --input-right-padding: 0;
    --input-shadow: var(--shadow-bottom-2-light);
    --input-focus-border: var(--neutral-1);
    --input-placeholder-color: var(--neutral-2);

    &:hover {
      --input-shadow: var(--shadow-bottom-4);
    }

    .inputContainer {
      transition: all 0.3s;
    }

    input {
      padding-left: 0;
      font-size: var(--input-placeholder-font-size);
    }

    svg {
      color: var(--neutral-2);
    }

    &.is-medium {
      input {
        padding-right: var(--space-2);
        font-size: var(--label-text-sm);
      }

      .inputContainer {
        > svg {
          @include size(20px);
        }
      }
    }

    &.is-medium-large {
      --input-height: 44px;
      --input-left-padding: var(--space-6);
      --submit-button-x-padding: var(--space-5);
      --submit-button-y-padding: var(--space-1);

      .submitButton {
        margin: 5px 0;
        margin-right: 6px;
        margin-left: 0;
      }

      .clearButton {
        margin: 0 var(--space-3);
      }
    }

    &.is-semi-large {
      --input-height: 52px;
      --input-left-padding: var(--space-6);
      --submit-button-x-padding: var(--space-5);
      --submit-button-y-padding: var(--space-2);

      .submitButton {
        margin: 5px 0;
        margin-right: 6px;
        margin-left: 0;
      }

      .clearButton {
        margin: 0 var(--space-3);
      }
    }
  }

  &.variant-hp {
    --input-border-radius: calc(var(--input-height) / 2);
    --input-placeholder-color: var(--neutral-3);
    --input-border: 1px solid var(--p-gray-color-5);

    &.is-x-large {
      --input-background-color: var(--white);
      --input-left-padding: var(--space-17);
      --input-right-padding: var(--space-6);
      --search-icon-left: var(--space-6);
      .searchIcon {
        @include size(32px);
      }

      .inputContainer {
        padding-left: var(--space-17);
      }

      svg {
        path {
          fill: var(--neutral-3);
        }
      }
    }

    &.is-large {
      --input-background-color: var(--white);

      svg {
        path {
          fill: var(--neutral-2);
        }
      }
    }

    .clearButton {
      svg {
        path {
          fill: var(--neutral-1);
        }
      }
    }

    input {
      padding-left: 0;
    }

    .inputContainer {
      &:focus-within {
        --input-background-color: var(--white);

        > svg {
          path {
            fill: var(--neutral-2);
          }
        }
      }

      &:hover:not(:focus-within) {
        backdrop-filter: blur(8px);
        --input-background-color: var(--white);
        --input-border: 1px solid var(--primary-variant-2);
        --search-icon-left: calc(var(--space-6) - 1px);
      }
    }
  }

  &.with-search-icon {
    --input-left-padding: var(--space-12);
  }

  &.is-large.with-search-icon {
    --input-left-padding: var(--space-12);
  }

  &.with-clear,
  &.with-button {
    --input-right-padding: var(--space-2);
    --grid-columns: auto max-content max-content;
  }

  &.with-clear.with-button {
    --grid-columns: auto max-content max-content;
  }

  &.with-button {
    --input-right-padding: 0;
  }

  &.is-attached .inputContainer {
    --input-border: 1px solid var(--field-interaction-pressed);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.act-as-button * {
    pointer-events: none !important; // only form element button will have click events
  }

  &.unstyled-dropdown {
    --dropdown-box-shadow: none;
    --dropdown-border-radius: 0;
  }

  @include until-phone() {
    --input-placeholder-font-size: var(--label-text-sm);
  }

  .noInputBorder {
    --input-border: none;
  }

  .inputContainer {
    overflow: hidden;
    height: var(--input-height);
    border: var(--input-border);
    border-radius: var(--input-border-radius);
    padding-left: var(--input-left-padding);
    padding-right: var(--input-right-padding);
    background-color: var(--input-background-color);
    box-shadow: var(--input-shadow);

    display: grid;
    grid-template-columns: var(--grid-columns);
    isolation: isolate;

    &:not(.noInputBorder):focus-within {
      --input-border: 1px solid var(--input-focus-border);
    }

    .placeholder {
      position: absolute;
      white-space: nowrap;
      top: 50%;
      transform: translateY(-50%);
      max-width: 100%;
      overflow: hidden;
      z-index: -1;
    }

    .searchIcon {
      position: absolute;
      top: 50%;
      left: var(--search-icon-left);
      transform: translateY(-50%);
    }

    .clearButton {
      align-self: center;

      &.hidden {
        visibility: hidden;
      }
    }

    .submitButton {
      color: var(--text-color);
      cursor: pointer;
      background: var(--primary);
      @include padding-x(var(--submit-button-x-padding));
      @include padding-y(var(--submit-button-y-padding));
      border-radius: var(--submit-button-border-radius);

      &:hover {
        background: var(--yellow-interaction-hover);
      }

      &:active {
        background: var(--yellow-interaction-pressed);
      }

      svg {
        display: block;
      }
    }

    input {
      width: 100%;
      height: 100%;
      padding-right: var(--space-1); // to add some space after placeholder ellipsis
      color: var(--input-text-color);

      &:placeholder-shown {
        text-overflow: ellipsis;
        color: var(--input-placeholder-color);
      }
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        text-overflow: ellipsis;
        color: var(--input-placeholder-color);
        font-size: var(--input-placeholder-font-size);
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        text-overflow: ellipsis;
        color: var(--input-placeholder-color);
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        text-overflow: ellipsis;
        color: var(--input-placeholder-color);
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        text-overflow: ellipsis;
        color: var(--input-placeholder-color);
      }
    }
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/*#region scrollbar */
// uncomment this if custom scroll styling is required
// ::-webkit-scrollbar {
//   width: var(--scrollbar-width);
//   background: var(--neutral-6);
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: var(--border-radius-xl);
//   background: var(--secondary);
// }
/*#endregion */

body {
  --phone-width: #{$screen-phone};
  --tablet-width: #{$screen-tablet};

  color: var(--text-color);
  overflow-x: hidden;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.56;
  width: 100vw; // to make sure custom scroll width is not consuming space from body, so that the background will not shift to right when auth modal opens
}

button,
input,
textarea,
select {
  outline: none;
  background: transparent;
  border: none;
  text-transform: inherit;
  font-size: inherit;
  font-family: inherit;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

/*#region CSS reset for fieldset */
fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}
fieldset legend {
  padding: 0;
  display: table;
}
body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}
/*#endregion*/

@include until-phone {
  // ios mobile safari auto zoom on input with font size less than 16px
  // makes sense to display bigger font size on mobile devices so target all agents
  input,
  textarea {
    font-size: 16px !important;
  }
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

ul {
  list-style-type: none;
}

#root {
  -webkit-overflow-scrolling: touch;
}

// reset default state of summary tag
summary {
  list-style: none;

  &::marker,
  &::-webkit-details-marker {
    display: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.heading {
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: -0.5px;
  color: #04052e;

  @include until-phone {
    margin-bottom: 16px;
    padding: 0 var(--space-4);
    font-size: 18px;
  }
}

#live-chat-widget-iframe {
  overflow: hidden;
  display: none;
}

.supportDropdown {
  color: var(--text-color-grey-1);
  cursor: pointer;
  position: relative;
  padding: var(--space-2) 0;
  min-width: 253px;

  .item {
    padding: var(--space-4) var(--space-8);
    display: flex;
    gap: var(--space-4);
    white-space: nowrap;
    width: 100%;
    text-align: left;

    &Content {
      display: flex;
      flex-direction: column;
      gap: var(--space-2);
      align-items: flex-start;
    }

    > div,
    span {
      flex: 1;
      margin-top: var(--space-1-half);
    }

    &:hover {
      background-color: var(--grey-interaction-standard);
    }
  }
}

@import 'scss/media-queries';

.modalOverlay {
  $animTime: 0.3s;

  z-index: var(--overlay-modal-inactive-zindex);
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  will-change: opacity, transform;
  pointer-events: none;

  &._left {
    transform: translate(-100%, 0);
  }

  &._top {
    transform: translate(0, -100%);
  }

  &._right {
    transform: translate(100%, 0);
  }

  &._bottom {
    transform: translate(0, 100%);
  }

  @keyframes slideAnim {
    to {
      transform: translate(0, 0);
    }
  }

  &._white {
    background-color: var(--primary-variant-1);
  }

  &._padding {
    padding: var(--space-10) var(--space-5);
  }

  &._active {
    z-index: var(--overlay-modal-zindex);
    opacity: 1;
    transition: opacity $animTime;
    animation: slideAnim $animTime forwards;
    pointer-events: auto;

    &.blur {
      /* if backdrop support: very transparent and blurred */
      @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        background: rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(16px);
      }
    }
  }

  &Close {
    z-index: 9999;
    position: absolute;
    right: 80px;
    top: 40px;
    cursor: pointer;
    color: var(--text-white) !important;

    @include until-desktop {
      right: var(--space-4);
      top: var(--space-4);
    }
  }
}

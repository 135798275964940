@import 'scss/mixins';
@import 'scss/typography';

.breadcrumbs {
  position: relative;
  height: var(--breadcrumbs-height);
  transition: transform 0.3s;
  will-change: transform;
  background-color: var(--breadcrumb-bg);

  &.noBackground {
    background-color: unset;
  }

  &Inner {
    @include maxContentWidth;
    overflow-x: auto;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 auto;

    @include until-phone {
      min-width: 100%;
      padding: 0 var(--space-4);

      &::-webkit-scrollbar {
        height: 0; /* Remove scrollbar space */
      }
    }
  }

  &Link {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: 0 var(--space-1);
    color: var(--text-color-grey-2);

    &.size-small {
      @include text-sm;
    }
    &.size-medium {
      @include label-sm;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      pointer-events: none;
      color: var(--text-color-grey-1);
    }
  }

  .separator {
    height: var(--height) !important;
    width: var(--width) !important;
    fill: var(--text-color-grey-2);
    display: block;
    display: flex;
    flex-shrink: 0;

    &.size-small {
      --height: 8.5px;
      --width: 8px;
    }
    &.size-medium {
      --height: 12.5px;
      --width: 12px;
    }
  }
}

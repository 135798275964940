@import 'scss/media-queries';
@import 'scss/typography';
@import 'scss/mixins';

.summaryStickyButton {
  position: fixed;
  right: 114px;
  bottom: 24px;
  z-index: var(--sizebar-zindex);

  button {
    box-shadow: 0px var(--space-1-half) 8px 0px rgba(0, 0, 0, 0.16);
  }

  @include until-phone {
    right: 16px;
  }
}

.summaryDrawer {
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
  width: 100%;
  height: 100%;

  .container {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.25);

    .panel {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 296px;
      transform: translateX(100%);
      transition: transform 0.2s, opacity 0.2s ease-in-out;
      will-change: transform;

      &.active {
        transform: translateX(0);
      }

      > div:first-child {
        position: relative;
        box-shadow: var(--shadow-bottom-4);
      }
    }
  }

  @include until-phone {
    top: 0;
  }

  &.active {
    opacity: 1;
    z-index: calc(var(--header-nav-zindex) - 1);
  }

  .header {
    display: grid;
    gap: var(--space-4);
    grid-template-columns: 1fr 24px;
    align-items: center;
    margin: var(--space-6) 0;
    color: var(--neutral-1);

    h2 {
      @include header-5;
      @include until-phone {
        @include header-4;
      }

      font-weight: var(--font-weight-extra-bold);
    }
  }

  .list {
    color: var(--neutral-1);
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--card-border-color);
      margin: var(--space-4) 0;
    }
  }
}

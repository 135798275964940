:root {
  --primary: #e5fb70;
  --primary-variant-1: #ffffff;
  --primary-variant-2: #000000;

  --secondary: #2892f4;
  --secondary-opaque-60: #2892f499;
  --secondary-opaque-25: #2892f440;
  --secondary-variant-1: #f99f1b;
  --secondary-variant-2: #d6f8d6;
  --secondary-variant-2-shadow: #b0cab0;
  --secondary-variant-2-opaque-30: #d6f8d64d;

  --info: #3b8dee;
  --info-light: #dff5ff;
  --info-light-opaque-50: #dff5ff80;
  --info-border: #a5dcf7;
  --info-text: #00266b;

  --success: #13c645;
  --success-light: #d5f8df;
  --success-1: #00644c;

  --warning: #f99f1b;
  --warning-light: #ffeedd;
  --warning-1: #f9eca1;

  --error: #f10b0b;
  --error-light: #fbe1e1;

  --cancel: #000000;
  --cancel-light: #f1f1f1;

  --processing: #621fff;
  --processing-light: #ede6fd;

  --white: #fff;
  --dark-green: #107e2f;
  --dark-green-opaque-1: #107e2f1a;
  --red: #f65c5c;

  --neutral-1: #333333;
  --neutral-2: #666666;
  --neutral-3: #999999;
  --neutral-4: #c4c4c4;
  --neutral-4-opaque-50: #c4c4c480;
  --neutral-5: #f1f1f1;
  --neutral-6: #f6f6f6;
  --neutral-6-opaque-80: #f6f6f6cc;
  --neutral-7: #f2f8fa;
  --neutral-8: #fafafa;
  --neutral-9: #b2b2b2;

  --p-gray-color-3: #999999;
  --p-gray-color-4: #c8c8c8;
  --p-gray-color-5: #d8d8d8;
  --p-gray-color-6: #f1f1f1;

  --grey-300: #e0e0e0;
  --grey-400: #ebebeb;

  --kf-silver: #8c9091;
  --kf-gold: #8c734b;

  --sia-interaction-standard: var(--info-text);
  --sia-interaction-hover: #003494;
  --sia-interaction-pressed: var(--info-text);
  --sia-interaction-disabled: var(--neutral-6);
  --sia-interaction-text-color: white;
  --sia-interaction-border-pressed: var(--info-text);
  --sia-interaction-text-pressed-color: var(--neutral-4);
  --sia-interaction-text-disabled-color: var(--neutral-9);

  --black-interaction-standard: var(--primary-variant-2);
  --black-interaction-hover: var(--neutral-1);
  --black-interaction-pressed: var(--primary-variant-2);
  --black-interaction-disabled: var(--neutral-6);
  --black-interaction-keyboard-focus: var(--primary-variant-2);
  --black-interaction-loading: var(--neutral-5);
  --black-interaction-text-color: var(--primary-variant-1);
  --black-interaction-text-hover-color: var(--primary-variant-1);
  --black-interaction-text-pressed-color: var(--neutral-4);
  --black-interaction-text-keyboard-focus-color: var(--primary-variant-1);
  --black-interaction-text-disabled-color: var(--neutral-9);
  --black-interaction-text-loading-color: var(--neutral-3);
  --black-interaction-shadow-pressed: 0 0 0 2px var(--neutral-4);
  --black-interaction-shadow-keyboard-focus: 0 0 0 2px var(--primary-variant-1), 0 0 0 4px var(--neutral-1);

  --yellow-interaction-standard: var(--primary);
  --yellow-interaction-hover: #ebff82;
  --yellow-interaction-pressed: #d2ea56;
  --yellow-interaction-disabled: #eefaaf;
  --yellow-interaction-text-color: var(--primary-variant-2);
  --yellow-interaction-text-disabled-color: var(--neutral-9);

  --blue-interaction-standard: var(--secondary);
  --blue-interaction-hover: #45abff;
  --blue-interaction-pressed: #227ccf;
  --blue-interaction-disabled: #babad0;
  --blue-interaction-text-color: var(--primary-variant-1);
  --blue-interaction-text-disabled-color: var(--primary-variant-1);

  --grey-interaction-standard: var(--neutral-6);
  --grey-interaction-hover: var(--neutral-5);
  --grey-interaction-pressed: var(--neutral-6);
  --grey-interaction-disabled: var(--neutral-6);
  --grey-interaction-text-color: var(--neutral-1);
  --grey-interaction-text-pressed-color: var(--black-interaction-standard);
  --grey-interaction-text-disabled-color: var(--neutral-9);

  --grey-5-interaction-background-color: var(--primary-variant-1);
  --grey-5-interaction-background-hover-color: var(--neutral-5);
  --grey-5-interaction-background-pressed-color: var(--neutral-4);
  --grey-5-interaction-border-color: var(--neutral-3);
  --grey-5-interaction-border-hover-color: var(--primary-variant-2);
  --grey-5-interaction-text-color: var(--neutral-2);
  --grey-5-interaction-text-hover-color: var(--neutral-1);

  --white-interaction-shadow-pressed: 0 0 0 1px var(--neutral-2), 0 0 0 3px var(--neutral-4);
  --white-interaction-shadow-keyboard-focus: 0 0 0 1px var(--neutral-2), 0 0 0 2px var(--primary-variant-1),
    0 0 0 4px var(--neutral-1);

  --text-white: var(--primary-variant-1);
  --text-color: var(--primary-variant-2);
  --text-color-grey-1: var(--neutral-1);
  --text-color-grey-2: var(--neutral-2);
  --text-color-grey-3: var(--neutral-3);
  --text-color-grey-4: var(--neutral-4);
  --text-color-grey-5: var(--neutral-5);
  --text-color-tertiary: var(--secondary);
  --text-color-success: var(--success);
  --text-color-dark-green: var(--dark-green);
  --text-color-error: var(--error);
  --text-color-warning: var(--warning);
  --text-color-processing: var(--processing);

  --text-color-kf: #00266b;

  --header-nav-bg: var(--primary-variant-2);
  --header-nav-hover-bg: var(--neutral-1);
  --header-nav-text-color: var(--primary-variant-1);

  --breadcrumb-bg: var(--neutral-8);

  --tooltip-bg: var(--neutral-1);
  --tooltip-text: var(--text-white);

  --tooltip-variant-1-bg: var(--primary-variant-1);
  --tooltip-variant-1-text: var(--primary-variant-2);

  --popover-variant-1-bg: var(--primary-variant-1);

  --field-interaction-standard: var(--neutral-3);
  --field-interaction-hover: var(--neutral-1);
  --field-interaction-pressed: var(--primary-variant-2);
  --field-interaction-disabled: var(--neutral-9);
  --field-interaction-error: var(--error);
  --field-interaction-text-color: var(--neutral-1);
  --field-interaction-text-disabled-color: var(--neutral-9);
  --field-interaction-placeholder-color: var(--neutral-2);
  --field-interaction-placeholder-pressed-color: var(--primary-variant-2);
  --field-interaction-placeholder-disabled-color: var(--neutral-9);
  --field-interaction-placeholder-error-color: var(--error);
  --field-interaction-helper-color: var(--neutral-3);
  --field-interaction-helper-error-color: var(--error);
  --field-interaction-background-disabled-color: var(--neutral-5);

  --card-border-color: var(--neutral-4);
  --card-focus-outline-color: var(--neutral-1);

  --gradient-loading-bg: linear-gradient(
    -45deg,
    #eee,
    #eee,
    #eee,
    #eee,
    #eee,
    #fff,
    #eee,
    #eee,
    #eee,
    #eee,
    #eee
  );

  --kf-logo: #427ac6;
  --kf-logo-dark-blue: #1838cd;
  --kf-experience: #1937cd;

  --pink-1: #ffdcdc;

  --easing-back-out: cubic-bezier(0.175, 0.885, 0.32, 1);

  --gold-1: #b57e00;
  --gold-2: #b98202;
  --gold-3: #ffeec5;
  --gold-4: #ffd600;
  --gold-5: #e2ae01;
  --gold-6: #b06a02;
}

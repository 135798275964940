// screens
$screen-phone: 600px;
$screen-tablet: 768px;
$screen-desktop: 1024px;
$screen-wide: 1366px;

@mixin mediaMinWidth($width) {
  @media (min-width: ($width + 1px)) {
    @content;
  }
}

@mixin mediaMaxWidth($width) {
  @media (max-width: $width) {
    @content;
  }
}

// phone media query
@mixin from-phone() {
  @include mediaMinWidth($screen-phone) {
    @content;
  }
}

@mixin until-phone() {
  @include mediaMaxWidth($screen-phone) {
    @content;
  }
}

// tablet media query
@mixin from-tablet() {
  @include mediaMinWidth($screen-tablet) {
    @content;
  }
}

@mixin until-tablet() {
  @include mediaMaxWidth($screen-tablet) {
    @content;
  }
}

// desktop media query
@mixin from-desktop() {
  @include mediaMinWidth($screen-desktop) {
    @content;
  }
}

@mixin until-desktop() {
  @include mediaMaxWidth($screen-desktop) {
    @content;
  }
}

// wide screen media query
@mixin from-wide-screen {
  @include mediaMinWidth($screen-wide) {
    @content;
  }
}

@mixin until-wide-screen {
  @include mediaMaxWidth($screen-wide) {
    @content;
  }
}

@import 'scss/typography';
@import 'scss/media-queries';
@import 'scss/mixins';

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.title {
  @include label-lg-bold;
  margin-bottom: var(--space-2);
  padding: 0 var(--space-6);

  @include until-phone {
    padding: 0 var(--space-4);
  }
}

.titleMd {
  @include label-md-bold;
}

.destinationContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .destination {
    --stagger-delay: 0s;
    padding: var(--space-2) var(--space-6);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--space-3);
    transition: all 0.3s;
    cursor: pointer;
    text-align: left;
    opacity: 0;
    position: relative;
    animation: slideIn 0.3s var(--stagger-delay) ease-in-out forwards;

    &Icon {
      position: absolute;
      top: 50%;
      right: var(--space-6);
      width: 32px;
      opacity: 0;
      transform: translate(-15%, -50%);
      transition: opacity 180ms ease-in-out, transform 200ms ease-in-out;
      color: var(--text-color-grey-1);
      display: flex;
      align-items: center;
    }

    &.active,
    &:hover {
      background-color: var(--neutral-6);

      .destinationIcon {
        opacity: 1;
        transform: translate(0, -50%);
      }

      .destinationContent {
        max-width: 132px;
      }
    }

    &Image {
      border-radius: var(--border-radius-md);
      object-fit: cover;

      &Container {
        position: relative;
        @include size(64px);
      }
    }

    &Content {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-width: 176px;

      &Container {
        display: flex;
        align-items: center;
        gap: var(--space-3);
        flex: 1;
      }

      &Name {
        @include label-md;
        font-weight: var(--font-weight-bold);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--text-color-grey-1);
      }

      &Country {
        @include label-sm;
        color: var(--text-color-grey-2);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  @include until-phone {
    grid-template-columns: 1fr;

    .destination {
      padding: var(--space-2) var(--space-4);

      &Content {
        max-width: 60vw;
      }
    }
  }
}

.regionChipLoading {
  @include placeholder;
  height: 38px;
  width: 100px;
  display: inline-block;
  margin-right: var(--space-2);
  border-radius: var(--border-radius-32);
}

.destinationLoading {
  padding: var(--space-2) var(--space-6);
  display: flex;
  align-items: center;
  gap: var(--space-3);

  .image {
    border-radius: var(--border-radius-md);
    @include size(64px);
    @include placeholder;
  }

  .body {
    flex: 1;
    max-width: 132px;
  }

  .title {
    @include placeholder;
    border-radius: var(--border-radius-md);
    width: 50%;
    height: 24px;
  }

  .content {
    @include placeholder;
    border-radius: var(--border-radius-md);
    height: 20px;
  }

  @include until-phone {
    padding: var(--space-2) var(--space-4);

    .body {
      max-width: 60vw;
    }
  }
}

.regionChips {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 var(--space-6);
  position: relative;

  .chipContainer {
    padding: var(--space-2) 0;
    overflow-x: auto;
    overflow-y: visible;
    white-space: nowrap;
    position: relative;

    > div {
      padding-right: 0;
    }

    .chip {
      margin-bottom: 0;

      &:first-child {
        margin-left: var(--space-1-half);
      }

      &:last-child {
        margin-right: var(--space-1-half);
      }
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .sliderArrowContainer {
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    width: 114px;

    &.previous {
      left: 24px;
      justify-content: flex-start;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 93.75%);
    }

    &.next {
      right: 24px;
      justify-content: flex-end;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 93.75%);
    }

    .sliderArrow {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--white);
      border: 1px solid var(--black-interaction-standard);
      border-radius: var(--border-radius-lg);
      @include size(24px);
    }
  }

  .hidden {
    visibility: hidden;
  }

  @include until-phone {
    padding: 0;

    .chipContainer {
      padding-left: var(--space-4);
      padding-right: var(--space-4);
    }

    .sliderArrowContainer {
      display: none;
    }
  }
}

@import 'scss/media-queries';

.yellowAiButton {
  position: fixed;
  bottom: 28px;
  right: 20px;
  z-index: 99999;
  cursor: pointer;

  &.aboveBanner {
    bottom: 60px;
  }

  img {
    height: 90px;
    width: 90px;
  }

  @include until-phone {
    display: none;
  }
}

.hide {
  display: none;
}

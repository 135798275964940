@import 'scss/typography';

.kfTier {
  padding: var(--space-2);
  border-radius: var(--border-radius-sm);
  display: inline-flex;
  align-items: center;
  gap: var(--space-1);
  color: var(--white);

  span {
    @include text-xs-bold;

    letter-spacing: 1.17px;
    text-transform: uppercase;
    margin-top: var(--space-1-half) !important;
  }

  svg {
    margin-right: 0;
  }

  &.variant-default {
    background-color: transparent;
    border: 1px solid var(--white);
  }

  &.variant-silver {
    background-color: var(--kf-silver);
  }

  &.variant-gold {
    background-color: var(--kf-gold);
  }
}

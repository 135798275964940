@import 'scss/mixins';

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  &Container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: var(--space-4);
  }

  @include size(20px);
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid var(--primary);
  border-top-color: black;
  animation: spin 1s linear infinite forwards;
}

@import 'scss/mixins';

.ratioBox {
  position: relative;

  &::before {
    height: 0;
    content: '';
    display: block;
    padding-bottom: calc(var(--aspect-ratio) * 100%);
  }

  > :first-child:not(:global(.observer)),
  > :global(.observer + .loadingElement) + img,
  > :global(.observer + .loadingElement) {
    @include absFullSize();
  }
}

@import 'scss/media-queries';
@import 'scss/mixins';
@import 'scss/typography';

.popup {
  --overlay-background: rgba(0, 0, 0, 0.25);
  --z-index: var(--modal-zindex);
  --modal-background-color: var(--white);
  --max-width: 480px;

  &.bringToFront {
    --z-index: var(--overlay-modal-zindex);
  }

  background-color: var(--overlay-background);
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: var(--z-index);
  opacity: 0;
  transition: opacity 300ms ease-in-out;

  @mixin container {
    background-color: var(--modal-background-color);
    display: flex;
    flex-direction: column;
    max-height: var(--max-height);
    opacity: 0;
  }

  @mixin header {
    display: flex;
    align-items: center;
    color: var(--text-color-grey-1);
    border-radius: 24px 24px 0px 0px;
    border-bottom: 1px solid var(--neutral-5);
  }

  @mixin title {
    flex-grow: 1;
  }

  @mixin body {
    flex-grow: 1;
    overflow-y: auto;
    position: relative;
    color: var(--text-color-grey-1);
  }

  @mixin footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-color-grey-1);
    border-top: 1px solid var(--neutral-5);
  }

  &.promo {
    --modal-background-color: var(--secondary);
    color: var(--white);
    text-align: center;

    @include until-tablet {
      --max-width: 358px;

      .container {
        margin: 0 var(--space-5);
      }
    }
  }

  &.animation {
    opacity: 1;

    &.asDialog .container,
    &.asBottomSheet .container {
      transform: none;
      opacity: 1;
    }
  }

  .fade {
    transition: opacity 200ms ease-in-out;
  }

  .slideUp {
    transform: translateY(100%);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  }

  &.asDialog {
    .flex-center {
      display: flex;
      align-items: center;
      justify-content: center;

      @include until-phone {
        width: 100%;
      }
    }

    .container {
      @include container;

      border-radius: var(--border-radius-24);
      width: var(--width);
      min-height: var(--min-height);
      padding-top: var(--top-padding);
      padding-bottom: var(--bottom-padding);
      position: relative;

      &.center {
        text-align: center;
        margin-left: var(--space-4);
        margin-right: var(--space-4);
      }

      &.sideSpace {
        @include until-phone {
          margin-left: var(--space-4);
          margin-right: var(--space-4);
        }
      }

      &.xsmall {
        --h-padding: var(--space-4);
        --v-padding: var(--space-4);
        --top-padding: var(--space-4);
        --bottom-padding: var(--space-6);

        --width: 100%;
        --min-height: unset;
        --max-height: 576px;

        .header .title {
          @include header-6;
          @include until-phone {
            @include header-5;
          }
        }

        &.center {
          --h-padding: var(--space-6);
        }

        &.center .header .title {
          @include header-5;
          @include until-phone {
            @include header-4;

            font-weight: var(--font-weight-bold);
          }

          font-weight: var(--font-weight-bold);
        }

        &.fixedHeight {
          height: var(--max-height);
        }

        &.fixedWidth {
          width: var(--max-width);
        }
      }

      &.small {
        --h-padding: var(--space-6);
        --v-padding: var(--space-4);
        --top-padding: var(--space-4);
        --bottom-padding: var(--space-6);

        --width: 480px;
        --min-height: 400px;
        --max-height: 640px;

        &.center {
          --h-padding: var(--space-10);
          --bottom-padding: var(--space-10);

          @include until-phone {
            --width: 100%;
          }
        }

        &.fixedHeight {
          height: var(--max-height);
        }

        &.noMinHeight {
          --min-height: unset;
        }
      }

      &.medium {
        --h-padding: var(--space-6);
        --v-padding: var(--space-4);
        --top-padding: var(--space-4);
        --bottom-padding: var(--space-6);

        --width: 600px;
        --min-height: 400px;
        --max-height: 640px;

        &.center {
          --h-padding: var(--space-10);
          --bottom-padding: var(--space-10);

          @include until-phone {
            --width: 100%;
          }
        }

        &.fixedHeight {
          height: var(--max-height);
        }

        &.noMinHeight {
          --min-height: unset;
        }
      }

      &.large {
        --h-padding: var(--space-6);
        --v-padding: var(--space-4);
        --top-padding: var(--space-4);
        --bottom-padding: var(--space-6);

        --width: 800px;
        --min-height: 400px;
        --max-height: 640px;

        &.center {
          --h-padding: var(--space-10);
          --bottom-padding: var(--space-10);

          @include until-phone {
            --width: 100%;
          }
        }

        @include until-tablet {
          --width: 100%;
          margin: 0 var(--space-6);
        }

        &.fixedHeight {
          height: var(--max-height);
        }

        &.noMinHeight {
          --min-height: unset;
        }
      }

      &.xlarge {
        --h-padding: var(--space-6);
        --v-padding: var(--space-4);
        --top-padding: var(--space-4);
        --bottom-padding: var(--space-6);

        --width: 680px;
        --min-height: 400px;
        --max-height: calc(100vh - 128px);

        &.center {
          --h-padding: var(--space-10);
          --bottom-padding: var(--space-10);

          @include until-phone {
            --width: 100%;
          }
        }

        @include until-tablet {
          --width: 100%;
          margin: 0 var(--space-6);
        }

        &.fixedHeight {
          height: var(--max-height);
        }

        &.noMinHeight {
          --min-height: unset;
        }
      }

      &.hasFooter {
        --bottom-padding: var(--space-4);

        &.center {
          --bottom-padding: var(--space-6);
        }
      }

      .header {
        @include header;

        &.hasSVG {
          position: relative;
          border: none;
        }

        padding-left: var(--h-padding);
        padding-right: var(--h-padding);
        padding-bottom: var(--space-4);

        .svgBg {
          position: absolute;
          top: -16px;
          left: 50%;
          transform: translate(-50%, 0);
          width: 342px;
          height: 52px;

          @include until-phone {
            width: 262px;
            height: 42px;
            display: flex;
          }
        }

        .closeBtn {
          z-index: 1;
        }

        .title {
          @include title;
          @include header-5;
          @include until-phone {
            @include header-4;
          }
        }

        &.absolute {
          border-bottom: 0;
          padding-top: var(--space-7);
          padding-bottom: var(--space-4);

          .closeIcon {
            position: absolute;
            top: var(--space-4);
            right: var(--space-4);
          }

          .title {
            text-align: center;
            @include header-4;
          }
        }
      }

      &.center .header {
        border-bottom: none;
        align-items: unset;
        position: relative;

        .title {
          @include header-4;
          @include until-phone {
            @include header-3;

            font-weight: var(--font-weight-bold);
          }

          margin-top: var(--space-7);
          font-weight: var(--font-weight-bold);
        }

        svg {
          position: absolute;
          right: 16px;
        }
      }

      .body {
        @include body;

        padding: var(--v-padding) var(--h-padding) 0 var(--h-padding);
      }

      &.center .body {
        padding-top: 0;
      }

      .footer {
        @include footer;

        padding-top: var(--space-4);
        padding-left: var(--h-padding);
        padding-right: var(--h-padding);

        &.hasSingleButton {
          flex-direction: row-reverse;

          @include until-phone {
            flex-direction: unset;
          }
        }

        &.right {
          justify-content: flex-end;
        }
      }

      &.center .footer {
        border-top: none;

        &.hasSingleButton {
          flex-direction: unset;
        }
        &.alignCenterButton {
          align-self: center;
        }
      }
    }
  }

  &.asBottomSheet {
    .container {
      @include container;

      border-radius: var(--border-radius-24) var(--border-radius-24) 0 0;
      width: 100%;
      height: var(--height);
      position: absolute;
      left: 0;
      bottom: 0;

      --padding: var(--space-4);

      &.small {
        --height: 288px;
        --max-height: unset;
      }

      &.medium {
        --height: 408px;
        --max-height: unset;
      }

      &.large {
        --height: calc(100% - var(--space-4));
        --max-height: unset;
      }

      &.auto {
        --height: auto;
        --max-height: calc(100% - var(--space-4));
      }

      &.full-screen {
        --height: 100%;
        --max-height: unset;

        border-radius: 0;
      }

      .header {
        @include header;

        align-items: center;
        padding: var(--padding);

        .title {
          @include title;
          @include header-5;
          @include overflowedText;
        }
      }

      .body {
        @include body;

        padding: var(--padding);

        &.noPadding {
          padding: 0;
        }
      }

      .footer {
        @include footer;

        padding: var(--padding);

        &.vertical {
          flex-direction: column-reverse;
          align-items: unset;
        }
      }
    }
  }
}

.bodyWithFooter .container {
  height: 100%;
}

.noPadding {
  padding: 0 !important;
}

@import './mixins';

.rounded-xsm {
  border-radius: var(--border-radius-xsm);
}

.rounded-sm {
  border-radius: var(--border-radius-sm);
}

.rounded-md {
  border-radius: var(--border-radius-md);
}

.rounded-lg {
  border-radius: var(--border-radius-lg);
}

.rounded-xl {
  border-radius: var(--border-radius-xl);
}

.grey-1-link {
  @include link(var(--text-color-grey-1), var(--text-color-grey-1));
}

.tertiary-link {
  @include link(var(--text-color-tertiary), var(--text-color-tertiary));
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.hidden {
  display: none !important;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden !important;
}

.divider {
  width: 100%;
  height: 1px;
  padding-bottom: 50px;
  margin: 0 auto 20px;
  border-bottom: 1px solid #d8d8d8;

  @include until-phone {
    padding-bottom: 16px;
  }
}

.animate-pulse {
  @include pulsing;
  pointer-events: none;
}

.l-main {
  $mainRef: &;
  --padding-top: 0;

  position: relative;
  min-height: 100vh;
  padding-top: var(--padding-top);
  background: var(--p-gray-color-6);

  &--no-footer {
    padding-bottom: 0;
  }

  &--white-bg {
    background: #fff;
  }

  &--no-pad-bottom {
    padding-bottom: 0;
  }

  &--no-pad-top {
    padding-top: 0;
  }

  &--auto-min-height {
    min-height: auto;
  }

  &__content {
    @include maxContentWidth;
    margin: 0 auto;
  }
  &__blog {
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: var(--space-2);

    @include until-tablet {
      grid-template-columns: 1fr;
    }

    &__columns {
      grid-column: 2 / span 10;
      margin: var(--space-10) 0;

      @include until-tablet {
        grid-column: unset;
      }

      @include until-phone {
        margin: var(--space-6) 0;
      }
    }
  }
}

.flex-center {
  @include flex-center;
}

.flex-align-center {
  @include flex-align-center;
}

.mobile-hidden {
  @include mobileHidden;
}

.capitalize {
  text-transform: capitalize;
}

@import 'scss/media-queries';
@import 'scss/typography';
@import 'scss/mixins';

.card {
  cursor: pointer;

  --img-border: 4px;

  .image {
    padding-bottom: 100%;
    position: relative;
    height: 0;

    img {
      max-width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      object-fit: cover;
      overflow: hidden;
      border: var(--img-border) solid var(--white);
      border-radius: var(--border-radius-lg);
      box-shadow: var(--shadow-bottom-2);
    }
  }

  .content {
    display: grid; // need to show ellipsis on text
    justify-items: start;
    gap: var(--space-1);

    .name {
      color: var(--text-color-grey-1);
      max-width: 100%;
      @include overflowedText(1);
    }

    .count {
      @include text-sm;
      color: var(--text-color-grey-2);
      padding: var(--space-1-half) var(--space-2);
      background-color: var(--neutral-5);
      border-radius: var(--border-radius-sm);
      display: inline-block;
    }
  }

  &.checklist {
    --img-border: 2px;

    display: grid;
    align-items: center;
    grid-template-columns: 72px 1fr auto;
    gap: var(--space-4);

    .content {
      .name {
        @include label-md-bold;
      }
    }
  }

  &.vertical-card {
    .content {
      padding: var(--space-3) var(--space-2) var(--space-2);

      .name {
        @include header-5-bold;
      }

      .count {
        @include from-phone {
          @include label-sm;
        }
      }
    }
  }
}

.input {
  margin-top: 0 !important;
  width: 100%;
  flex: 1;

  > div {
    border-radius: 50px;
  }
}
.icon {
  color: var(--text-color-grey-2) !important;
}

@import 'scss/media-queries';
@import 'scss/typography';
@import 'scss/mixins';

.row {
  border-radius: var(--border-radius-md);
  border: 1px solid var(--card-border-color);
  padding: var(--space-4) var(--space-6);
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: var(--space-4);
  color: var(--text-color-grey-1);

  &:last-child {
    margin-top: var(--space-4);
  }
}

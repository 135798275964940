@import 'scss/typography';
@import 'scss/media-queries';

.mobileMenuAppBanner {
  .appBanner {
    padding: var(--space-4) var(--space-6);
    display: flex;
    align-items: center;
    gap: var(--space-2);
    background-color: var(--neutral-7);

    .label {
      @include label-sm;
      flex: 1;
      color: var(--primary-variant-2);

      svg {
        path {
          fill: var(--primary-variant-2);
        }
      }

      span {
        font-weight: var(--font-weight-extra-bold);
      }
    }

    .getApp {
      @include label-sm-bold;
      color: var(--neutral-1);
      text-decoration: underline;
      text-underline-offset: var(--space-1);
    }
  }

  .authBtns {
    &Container {
      padding: 0 var(--space-6);
    }

    display: flex;
    gap: var(--space-3);
    padding: var(--space-6) 0;
    border-bottom: 1px solid var(--neutral-4);
  }
}

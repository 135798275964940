@import 'scss/typography';
@import 'scss/mixins';

.cb {
  --background-color: transparent;
  --background-hover-color: var(--yellow-interaction-hover);
  --text-color: var(--neutral-2);
  --text-hover-color: var(--neutral-1);
  --text-active-color: var(--neutral-1);
  --text-disable-color: var(--neutral-3);

  --checkbox-color: var(--neutral-3);
  --checkbox-fill-color: var(--white);
  --checkbox-active-color: var(--primary-variant-2);
  --checkbox-active-tick-color: var(--white);
  --checkbox-active-fill-color: var(--primary-variant-2);
  --checkbox-disable-color: var(--neutral-4);
  --checkbox-disable-fill-color: var(--neutral-6);
  --checkbox-disable-border-color: var(--neutral-9);

  --transition-time: 0.15s;
  --label-left-margin: 8px;

  display: inline-flex;
  align-items: flex-start;
  background-color: var(--background-color);

  &:hover {
    outline: none;
    input[type='checkbox']:not(:checked) {
      background-color: var(--background-hover-color);
    }

    .cbIconContainer {
      border-color: var(--checkbox-color);
    }

    .cbLabel {
      color: var(--text-hover-color);
    }
  }

  input[type='checkbox'] {
    &:checked,
    &:indeterminate {
      + .cbIconContainer {
        background-color: var(--checkbox-active-fill-color);
        border-color: var(--checkbox-active-color);

        .cbIcon {
          opacity: 1;
          color: var(--checkbox-active-tick-color) !important;

          path {
            stroke: var(--checkbox-active-tick-color);
          }
        }
      }

      ~ .cbLabel {
        color: var(--text-active-color);
      }
    }
    &:focus {
      + .cbIconContainer {
        border-color: var(--checkbox-color);
      }

      &:checked {
        + .cbIconContainer {
          border-color: var(--checkbox-active-color);
        }
      }

      ~ .cbLabel {
        color: var(--text-hover-color);
      }
    }

    &:disabled {
      + .cbIconContainer {
        background-color: var(--checkbox-disable-fill-color);
        border-color: var(--checkbox-disable-border-color);

        .cbIcon path {
          fill: var(--checkbox-disable-color);
          stroke: var(--checkbox-disable-color);
        }
      }

      &:checked,
      &:hover {
        ~ .cbLabel {
          color: var(--checkbox-disable-border-color);
        }
      }
    }
  }
}

.cbLabel {
  flex: 1;
  margin-left: var(--space-2);
  color: var(--text-color);
  align-self: center;
  @include label-sm;
}

.cbIconContainer {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--checkbox-color);
  border-radius: var(--border-radius-xsm);
  will-change: background-color;
  transition: background-color var(--transition-time);
}

.cbIcon {
  opacity: 0;
  will-change: opacity;
  transition: opacity var(--transition-time);
}

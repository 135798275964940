@import 'scss/typography';
@import 'scss/media-queries';

.appBannerContainer {
  position: relative;
  width: 100%;
  max-width: var(--container-max-width);
  margin-left: auto;
  margin-right: auto;
  padding: 0 var(--page-side-space);

  &.noPadding {
    padding: 0;
  }

  @include until-tablet {
    padding: 0;

    &.noPadding {
      .mobileAppBanner {
        border-radius: var(--border-radius-md);
      }
    }
  }

  @include until-phone {
    &.noPadding {
      .mobileAppBanner {
        border-radius: 0;
      }
    }
  }
}

.bannerContainer {
  position: relative;
  padding-top: var(--space-10);

  &.compact {
    padding-top: 0;
  }

  .normalAppMockup {
    position: absolute;
    bottom: 0;
    left: 114px;
    transform: translateY(0%);
    height: 100%;
    width: 240px;
    overflow: hidden;

    img {
      object-fit: cover;
      height: auto;
    }
  }

  @include until-desktop {
    .normalAppMockup {
      left: 24px;
    }
  }

  @include until-tablet {
    padding-top: 0;
    overflow-y: hidden;

    .normalAppMockup {
      left: unset;
      right: 20px;
      transform: translateY(20%);
      width: 84px;
      height: auto;

      img {
        height: 100%;
      }
    }
  }
}

.mobileAppBanner {
  background-color: var(--primary-variant-2);
  position: relative;
  overflow: hidden;
  padding-left: 354px;
  color: white;
  border-radius: var(--border-radius-lg);

  &.compact {
    padding-left: 0;
    border-radius: var(--border-radius-md);

    .illustration {
      transform: translate(-23%, -15%) rotate(-15deg);
    }

    .content {
      flex-direction: row;
      align-items: center;

      gap: var(--space-6);
      margin: 0 auto;
      padding: var(--space-4) var(--space-6);
      position: relative;
      max-width: 837px;

      .innerContent {
        flex: 1;
      }

      .appMockup {
        &Container {
          position: relative;
          padding: var(--space-2);
          width: 108px;
          height: 108px;
        }

        position: absolute;
        top: 0;
        left: 0;
      }

      .title {
        @include header-4;
      }

      .description {
        @include label-lg-bold;
        margin-top: var(--space-1);
      }

      .qrCodeSection {
        align-items: center;
        min-width: 81px;

        span {
          color: var(--primary-variant-1);
        }

        svg {
          width: 70px;
          height: 70px;

          path,
          rect {
            transform: scale(0.5, 0.5);
          }
        }
      }
    }
  }

  .illustration {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-20%, 45%) rotate(-10deg);

    path {
      fill: var(--primary);
    }
  }

  .content {
    padding: var(--space-8) var(--space-6);
    max-width: 720px;
    display: flex;
    flex-direction: column;
    gap: var(--space-6);
    position: relative;
    z-index: 1;

    .title {
      @include header-2;

      span {
        color: var(--primary);
      }
    }

    .footer {
      display: flex;
      gap: var(--space-5);

      &Content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: var(--space-6);
        flex: 1;
        @include label-lg-bold;
      }

      .buttonContainer {
        display: flex;
        gap: var(--space-4);

        a {
          height: 41px;
        }
      }
    }

    .qrCodeSection {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: var(--space-1);
      @include text-xs;

      span {
        color: var(--primary-variant-1);
      }
    }
  }

  @include until-desktop {
    padding-left: 264px;

    .illustration {
      transform: translate(-18%, 10%) rotate(-15deg);
    }

    .footer {
      .buttonContainer {
        flex-wrap: wrap;
      }
    }
  }

  @include until-tablet {
    padding-left: 0;
    border-radius: 0;

    &.compact {
      border-radius: 0;

      .illustration {
        top: unset;
        bottom: 0;
        transform: translate(40%, 50%) rotate(-10deg);
      }

      .content {
        flex-direction: column;
        align-items: flex-start;
        gap: var(--space-8);
        padding: var(--space-4) var(--space-5);
        margin: 0;

        .appMockup {
          &Container {
            position: absolute;
            right: var(--space-5);
            top: 0;
            padding: 0;
            width: 84px;
          }

          transform: translateY(30%);
        }

        .title {
          @include header-3;
          max-width: 78%;
          padding-right: var(--space-4);
          position: relative;
          z-index: 1;
        }
      }
    }

    .illustration {
      left: unset;
      top: unset;
      right: 0;
      bottom: 0;
      transform: translate(40%, 50%) rotate(-10deg);
    }

    .content {
      padding: var(--space-4) var(--space-5);
      max-width: 75%;
      gap: var(--space-8);

      .title {
        @include header-3;
      }

      .footer {
        &Content {
          p {
            display: none;
          }
        }

        .qrCodeSection {
          display: none;
        }
      }
    }
  }
}

.qrCode {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-1);

  svg {
    width: 88px;
    height: 88px;

    path,
    rect {
      transform: scale(0.62);
    }
  }

  span {
    @include text-xs;
    color: var(--primary-variant-2);
    text-align: center;
  }
}

@import './media-queries';
/**
  spread scss map to css property and values
  (color: red) => { color: red }
*/
@mixin spread-props($item) {
  @each $property, $value in $item {
    #{$property}: $value;
  }
}

@mixin size($size) {
  width: $size;
  height: $size;
}

@mixin padding-x($space) {
  padding-left: $space;
  padding-right: $space;
}

@mixin padding-y($space) {
  padding-top: $space;
  padding-bottom: $space;
}

@mixin margin-x($space) {
  margin-left: $space;
  margin-right: $space;
}

@mixin margin-y($space) {
  margin-top: $space;
  margin-bottom: $space;
}

@mixin page-side-space-negative-margin {
  @include margin-x(calc(var(--page-side-space) * -1));
}

@mixin link($color, $activeColor: $color) {
  font-size: inherit;
  color: $color;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: $activeColor;
  }
}

@mixin absFullSize {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes skeletonShimmer {
  100% {
    transform: translateX(100%);
  }
}

@mixin pulsing($animTime: 1.5s) {
  animation: pulse $animTime ease-in-out infinite;
}

@mixin fadeIn($time: 1s) {
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn $time;
}

@mixin animateBg($time: 2s) {
  @keyframes animateBg {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
  animation: animateBg $time infinite reverse ease-out;
}

@mixin maxContentWidth {
  position: relative;
  width: 100%;
  max-width: var(--container-max-width);
  padding-left: var(--page-side-space);
  padding-right: var(--page-side-space);

  @include until-phone {
    padding-left: 0;
    padding-right: 0;
  }
}

@mixin placeholder($animTime: 1.5s) {
  @include pulsing($animTime);
  background: var(--neutral-5);
}

@mixin shimmer() {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 1.3em;
  background-color: var(--grey-300);
  opacity: 0.5;
  border-radius: var(--border-radius-md);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      var(--grey-300) 0%,
      var(--grey-400) 25%,
      var(--neutral-5) 65%,
      var(--grey-300) 100%
    );
    animation: skeletonShimmer 1.5s infinite;
  }
}

@mixin overflowedText($lines: 1, $force: false) {
  overflow: hidden;
  text-overflow: ellipsis;

  @if ($lines > 1) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    @if ($force) {
      white-space: normal;
    }
  } @else {
    white-space: nowrap;
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-align-center {
  display: flex;
  align-items: center;
}

@mixin mobileHidden {
  @include until-phone {
    display: none;
  }
}

@mixin tabletHidden {
  @include until-tablet {
    display: none;
  }
}

// media gallery mixins (move this to media gallery if only used there)
@keyframes appearingAnimKeyframes {
  to {
    opacity: 1;
  }
}

@mixin appearingAnimation($animTime: 1s, $delay: 0.2s) {
  animation: appearingAnimKeyframes $animTime $delay forwards;
}

@mixin isAppearing($animTime: 1s, $delay: 0.2s) {
  opacity: 0;
  @include appearingAnimation($animTime, $delay);
}

@mixin imgCoverCont {
  position: relative;
  @include isAppearing;

  img {
    @include absFullSize;
    object-fit: cover;
    object-position: center center;
    border-radius: inherit;
  }
}

$tag-variants: (
  'yellow': (
    bgColor: var(--primary),
    color: var(--primary-variant-2),
  ),
  'white': (
    bgColor: var(--white),
    color: var(--primary-variant-2),
  ),
  'black': (
    bgColor: var(--primary-variant-2),
    color: var(--white),
  ),
);

@each $name, $value in $tag-variants {
  .tag-#{$name} {
    background: map-get($value, bgColor);
    color: map-get($value, color);
  }
}

@mixin custom-scrollbar(
  $thickness: 6px,
  $thumb-color: var(--secondary),
  $bg-color: var(--neutral-6),
  $variant: 'default'
) {
  &::-webkit-scrollbar {
    background: $bg-color;

    // Set width/height based on orientation
    &:vertical {
      width: $thickness;
    }

    &:horizontal {
      height: $thickness;
    }

    &-thumb {
      border-radius: var(--border-radius-xl);
      background: $thumb-color;
    }

    @if ($variant == 'tiny') {
      &-track {
        border-radius: var(--border-radius-xl);
      }

      &-button {
        background: white;
        width: calc(calc(100% - var(--space-15)) / 4);
      }
    }
  }
}
// media gallery mixins end

@mixin regionLoading {
  display: block;

  &:before {
    content: '…';
    color: transparent;
  }
  @include placeholder;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin animation($name, $dur, $easing, $delay, $repeat) {
  //@mixin animation($name, $dur, $easing, $delay, $repeat, $direction, $fillMode, $state){
  /* @keyframes name | duration | timing-function | delay |
     iteration-count | direction | fill-mode | play-state */
  /*animation: slidein 3s ease-in 1s 2 reverse both paused;*/

  -webkit-animation: $name $dur $easing $delay $repeat; //$direction $fillMode $state;
  -moz-animation: $name $dur $easing $delay $repeat; //$direction $fillMode $state;
  -ms-animation: $name $dur $easing $delay $repeat; //$direction $fillMode $state;
  -o-animation: $name $dur $easing $delay $repeat; //$direction $fillMode $state;
  animation: $name $dur $easing $delay $repeat; //$direction $fillMode $state;
}

@import 'scss/typography';

.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 0;
  cursor: pointer;
}

.popperContainer {
  display: inherit;

  .popper {
    display: inherit;

    .trigger {
      display: inherit;
      width: auto;
      cursor: pointer;
    }

    .tooltip {
      z-index: 1;
      position: relative;
      max-width: 336px;
      background: var(--tooltip-bg);
      color: var(--tooltip-text);
      padding: var(--space-2) var(--space-4);
      border-radius: var(--border-radius-sm);
      @include text-sm;
      font-weight: normal;
      display: none;
      width: max-content; // this is required to make sure that the content is using max available space instead of wrapping to next line
      box-shadow: var(--shadow-bottom-2);

      &.white-variant {
        background: var(--tooltip-variant-1-bg);
        color: var(--tooltip-variant-1-text);
      }

      @include until-phone {
        max-width: 200px;
      }

      &[data-show] {
        display: block;
      }

      &[data-popper-placement^='top'] > .arrow {
        bottom: -4px;
      }

      &[data-popper-placement^='bottom'] > .arrow {
        top: -4px;
      }

      &[data-popper-placement^='left'] > .arrow {
        right: -4px;
      }

      &[data-popper-placement^='right'] > .arrow {
        left: -4px;
      }

      .arrow,
      .arrow::before {
        position: absolute;
        width: 8px;
        height: 8px;
        background: inherit;
      }

      .arrow {
        visibility: hidden;
      }

      .arrow::before {
        content: '';
        visibility: visible;
        transform: rotate(45deg);
      }
    }
  }

  &.popover-overlay {
    .popper .tooltip[data-show] + .overlay {
      display: block;
    }

    @include until-phone {
      & .tooltip {
        max-width: 75vw;
      }
    }
  }
}

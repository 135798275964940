@import './colors';
@import 'brand-assets/variable-overrides'; // only allow color overrides
@import '../media-queries';

:root {
  --border-radius-xsm: 2px;
  --border-radius-sm: 4px;
  --border-radius-smd: 6px;
  --border-radius-md: 8px;
  --border-radius-lg: 16px;
  --border-radius-xl: 100px;
  --border-radius-10: 10px;
  --border-radius-12: 12px;
  --border-radius-24: 24px;
  --border-radius-32: 32px;

  --shadow-1: 1px 1px 4px rgba(0, 0, 0, 0.08);
  --shadow-bottom-2: 0px 2px 8px rgba(0, 0, 0, 0.16);
  --shadow-bottom-2-light: 0px 2px 8px rgba(0, 0, 0, 0.1);
  --shadow-top-2: 0px -2px 8px rgba(0, 0, 0, 0.16);
  --shadow-bottom-4: 0px 4px 16px rgba(0, 0, 0, 0.16);
  --shadow-top-4-spread-4: 0px -4px 4px rgba(0, 0, 0, 0.08);
  --shadow-top-4: 0px -4px 16px rgba(0, 0, 0, 0.16);
  --shadow-top-8: 0px 8px 16px rgba(0, 0, 0, 0.16);
  --category-icon-shadow: 4px 2px 16px rgba(0, 0, 0, 0.16);
  --tooltip-arrow-shadow-top: -2px -2px 2px rgba(0 0 0 / 0.16);
}

:root {
  --header-nav-zindex: 1000;
  --chat-widget-zindex: 1000;
  --drawer-zindex: 10;
  --modal-zindex: 2000;
  --modal-cta-zindex: 2001;
  --tooltip-zindex: 1000;
  --tooltip-arrow-zindex: 1001;
  --sizebar-zindex: 1;
  --popover-zindex: 5;
  --overlay-modal-zindex: 10000;
  --overlay-modal-inactive-zindex: -10;
  --toast-zindex: 10001;
  --sticky-footer-zindex: 3;

  --header-height: 56px;

  @include until-phone() {
    --header-height: 64px;
  }

  --scrollbar-width: 8px;
  --notification-height: 0px;
  --breadcrumbs-height: 32px;

  --gutter: 8px;
}

/* typography start */
:root {
  --font-family: 'Nunito Sans', Helvetica, Arial, sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;

  --font-family-montserrat: 'Montserrat', Helvetica, Arial, sans-serif;
  --font-family-caveat: 'Caveat', Helvetica, Arial, sans-serif;
}

:root {
  --header-font-weight: var(--font-weight-extra-bold); // default is extra bold
  --header-font-color: var(--primary-variant-2);

  --header-display: 64px;
  --header-display-line-height: 72px;
  --header-display-letter-space: -0.01em;

  --header-1: 48px;
  --header-1-line-height: 56px;
  --header-1-letter-space: -0.0075em;

  --header-2: 40px;
  --header-2-line-height: 48px;
  --header-2-letter-space: -0.006em;

  --header-3: 32px;
  --header-3-line-height: 40px;
  --header-3-letter-space: -0.005em;

  --header-4: 24px;
  --header-4-line-height: 32px;
  --header-4-letter-space: -0.002em;

  --header-5: 18px;
  --header-5-line-height: 26px;
  --header-5-letter-space: -0.001em;

  --header-6: 16px;
  --header-6-line-height: 22px;
  --header-6-letter-space: -0.0009em;

  @include until-tablet() {
    --header-display: 48px;
    --header-display-line-height: 56px;
    --header-display-letter-space: -0.0075em;

    --header-1: 40px;
    --header-1-line-height: 48px;
    --header-1-letter-space: -0.006em;

    --header-2: 32px;
    --header-2-line-height: 40px;
    --header-2-letter-space: -0.005em;

    --header-3: 24px;
    --header-3-line-height: 32px;
    --header-3-letter-space: -0.002em;

    --header-4: 18px;
    --header-4-line-height: 26px;
    --header-4-letter-space: -0.001em;

    --header-5: 16px;
    --header-5-line-height: 22px;
    --header-5-letter-space: -0.0009em;

    --header-6: 14px;
    --header-6-line-height: 20px;
    --header-6-letter-space: -0.0009em;
  }
}

// body
:root {
  --body-text-sm: 16px;
  --body-text-md: 18px;
}

// label
:root {
  --label-text-sm: 14px;
  --label-text-md: 16px;
  --label-text-lg: 18px;
}

// other text
:root {
  --text-sm: 12px;
  --text-xs: 10px;
}
/* typography end */

/* spacing start */
// update $space-multipliers whenever a new space variable is getting added
// so that util class names will be created accordingly
// refer to _spacing.scss to check how util classes are getting generated
:root {
  --base-space: 4px;
  --space-0: 0px;
  --space-1-half: calc(var(--base-space) * 0.5);
  --space-1: calc(var(--base-space) * 1);
  --space-2: calc(var(--base-space) * 2);
  --space-3: calc(var(--base-space) * 3);
  --space-4: calc(var(--base-space) * 4);
  --space-5: calc(var(--base-space) * 5);
  --space-6: calc(var(--base-space) * 6);
  --space-7: calc(var(--base-space) * 7);
  --space-8: calc(var(--base-space) * 8);
  --space-9: calc(var(--base-space) * 9);
  --space-10: calc(var(--base-space) * 10);
  --space-11: calc(var(--base-space) * 11);
  --space-12: calc(var(--base-space) * 12);
  --space-13: calc(var(--base-space) * 13);
  --space-14: calc(var(--base-space) * 14);
  --space-15: calc(var(--base-space) * 15);
  --space-16: calc(var(--base-space) * 16);
  --space-17: calc(var(--base-space) * 17);
  --space-18: calc(var(--base-space) * 18);
  --space-19: calc(var(--base-space) * 19);
  --space-20: calc(var(--base-space) * 20);
  --space-21: calc(var(--base-space) * 21);
  --space-22: calc(var(--base-space) * 22);
  --space-23: calc(var(--base-space) * 23);
  --space-24: calc(var(--base-space) * 24);
  --space-26: calc(var(--base-space) * 26);
  --space-27: calc(var(--base-space) * 27);
  --space-28: calc(var(--base-space) * 28);
  --space-29: calc(var(--base-space) * 29);
  --space-30: calc(var(--base-space) * 30);
  --space-40: calc(var(--base-space) * 40);
  --space-44: calc(var(--base-space) * 44);
  --space-46: calc(var(--base-space) * 46);
  --space-55: calc(var(--base-space) * 55);
  --space-65: calc(var(--base-space) * 65);
  --space-72: calc(var(--base-space) * 72);
  --space-156: calc(var(--base-space) * 156);
  --space-180: calc(var(--base-space) * 180);

  --page-side-space: var(--space-4);
  --container-max-width: #{$screen-wide};

  @include from-tablet {
    --page-side-space: var(--space-6);
  }

  @include from-desktop {
    --page-side-space: calc(var(--space-20) - 1px);
  }
}
/* spacing end */

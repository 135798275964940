@import 'scss/typography';

.downloadAppDropdown {
  background-color: var(--neutral-7);
  padding: var(--space-4);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-2);
  width: 223px;
  color: var(--neutral-1);

  .title {
    @include label-sm;
    font-weight: var(--font-weight-semi-bold);
    font-family: var(--font-family-montserrat);
    text-align: center;

    span {
      font-weight: var(--font-weight-extra-bold);
    }
  }
}

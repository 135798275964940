@import 'scss/typography';
@import 'scss/mixins';

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(70%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.drawer {
  position: fixed;
  z-index: var(--drawer-zindex);
  top: calc(var(--notification-height));
  left: 0;
  @include size(100%);
  background: white;
  animation: slideLeft 0.2s ease-in-out;
  overflow: hidden; // to avoid showing overflow content in ios safari

  --drawer-nav-height: 112px; // change this value here if spacing between drawerNav children are gonna change

  &.noHeaderOffset {
    top: 0;
  }

  &.belowHeader {
    top: calc(var(--header-height) + var(--notification-height));
  }

  &.active {
    display: block;
  }

  .drawerNav {
    padding: var(--space-4) var(--space-6);
    display: grid;
    row-gap: var(--space-4);
    max-height: var(--drawer-nav-height);

    .backIcon {
      margin-bottom: var(--space-4);
    }

    .drawerTitle {
      display: flex;
      gap: var(--space-2);
      align-items: center;

      h3 {
        @include header-3-bold;
      }
    }
    padding-bottom: var(--space-6);
  }

  .drawerContent {
    overflow: auto;
    max-height: calc(100vh - var(--drawer-nav-height));
    padding: 0 var(--space-6) var(--space-40);

    .lists {
      display: grid;
      gap: var(--space-6);

      li {
        display: flex;
        gap: var(--space-4);

        .activeListItem {
          color: var(--text-color) !important;
          font-weight: var(--font-weight-bold);
        }
      }
    }
  }
}

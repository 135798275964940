@import 'scss/typography';

.dropdown {
  display: inline-block;
  background: white;

  &:not(.borderLess) {
    box-shadow: var(--shadow-bottom-2);
  }

  &.attachedBottom {
    border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
    border-bottom: 0;
  }

  .header {
    @include label-md-bold;
    display: flex;
    gap: var(--space-6);
    margin-bottom: var(--space-4);
    color: var(--text-color);
    padding: var(--space-6) var(--space-8) 0;
  }

  .content {
    display: flex;
    overflow: hidden;
    position: relative;
  }

  li {
    cursor: pointer;
    font-weight: normal;
    padding: var(--space-2) var(--space-8);
    width: 252px;

    &:hover {
      background-color: var(--grey-interaction-standard);
    }
    &.selected {
      font-weight: var(--font-weight-bold);
    }
    &:hover,
    &.selected {
      color: var(--neutral-1);
    }
    &:focus-visible {
      outline-color: var(--neutral-1);
    }
  }

  .currencies,
  .languages {
    text-transform: capitalize;
    display: grid;
    grid-template-rows: repeat(var(--items-per-row), min-content);
    grid-auto-flow: column;
    color: var(--text-color-grey-1);
    min-width: 100%;
    padding-bottom: var(--space-6);
  }

  .tabs {
    @include label-md;
    display: flex;
    gap: var(--space-6);
    margin-bottom: var(--space-6);
    color: var(--text-color-grey-2);
    font-weight: var(--font-weight-light);
    padding: var(--space-6) var(--space-8) 0;

    .currencies,
    .languages {
      opacity: 0;
      transition: transform 170ms ease-in, opacity 170ms ease-in-out;

      &.active {
        opacity: 1;
      }
    }

    li {
      position: relative;

      &.active {
        color: var(--text-color);
        font-weight: var(--font-weight-bold);
      }

      &:hover::after,
      &.active::after {
        content: '';
        height: 3px;
        width: 100%;
        background-color: var(--blue-interaction-standard);
        display: flex;
        position: absolute;
        bottom: -8px;
      }
    }

    .currencies {
      transform: translateX(-100%);

      &.active {
        transform: translateX(0%);
      }
    }

    .languages {
      transform: translateX(0%);

      li {
        max-width: 50%;
      }

      &.active {
        transform: translateX(-100%);
      }
    }
  }
}

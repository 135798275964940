@import 'scss/media-queries';
@import 'scss/mixins';
@import 'scss/typography';

.campaignModal {
  position: relative;
  width: 480px;
  display: flex;
  flex-direction: column;
  gap: var(--space-4);

  background-color: var(--background-color);
  color: var(--text-color);
  border-radius: var(--border-radius-24);
  text-align: center;

  @include until-phone {
    width: 100%;
  }

  img {
    border-top-left-radius: var(--border-radius-24);
    border-top-right-radius: var(--border-radius-24);
  }

  .spacingContainer {
    padding: 0 var(--space-10) var(--space-6) var(--space-10);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-4);

    @include until-phone {
      padding: var(--space-6);
      padding-top: 0;
    }

    .headerSpacing {
      padding-top: var(--space-13);

      @include until-phone {
        padding: var(--space-11);
      }
    }
  }

  // hashcode suffix comes from API hence hashcode is part of class name to make it dynamic
  &._theme_E5FB70 {
    --background-color: var(--primary);
    --text-color: var(--text-color);
  }
  &._theme_2892F4 {
    --background-color: var(--secondary);
    --text-color: var(--neutral-6);
  }
  &._theme_D6F8D6 {
    --background-color: var(--secondary-variant-2);
    --text-color: var(--text-color-grey-1);
  }
  &._theme_F9ECA1 {
    --background-color: var(--warning-1);
    --text-color: var(--text-color-grey-1);
  }
  &._theme_00644C {
    --background-color: var(--success-1);
    --text-color: var(--neutral-6);
  }
  &._theme_000000 {
    --background-color: var(--primary-variant-2);
    --text-color: var(--neutral-6);
  }
  &._theme_FFDCDC {
    --background-color: var(--pink-1);
    --text-color: var(--text-color-grey-1);
  }

  .ritchText {
    display: flex;
    gap: var(--space-1);

    a {
      text-decoration: underline;
      text-decoration-color: var(--text-color);
    }
  }

  .closeButton {
    position: absolute;
    cursor: pointer;
    width: fit-content;
    right: 16px;
    top: 16px;

    button {
      border: unset;
    }
  }
}

@import 'scss/mixins';
@import 'scss/typography';

.container {
  margin-top: var(--space-5);

  > button {
    z-index: var(--overlay-modal-zindex) !important;
    top: var(--space-6) !important;
  }
}

.modal {
  width: 600px;
  height: 640px;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-24);
  box-shadow: var(--shadow-top-8);
  overflow: hidden;

  position: relative;
  background: white;
  z-index: var(--drawer-zindex);

  &Body {
    max-height: 570px;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;

    &Content {
      padding: var(--space-4) 0;
    }

    &Placeholder {
      height: 42px;
      width: 100%;
    }
  }

  &Footer {
    padding: var(--space-4) var(--space-6);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--space-4);
    border-top: 1px solid var(--neutral-5);
  }

  @include until-phone {
    width: 100%;
    height: 100vh; // fallback - safari, firefox support - this removes extra scroll in safari firefox
    // https://stackoverflow.com/questions/60657502/setting-height-to-100vh-works-but-i-get-a-scroll-bar-in-mobile-browsers
    height: 100dvh; // chrome support - this removes extra scroll in chrome
    border-radius: 0;

    &Body {
      flex: 1;
      max-height: none;
    }

    &Footer {
      padding: var(--space-4);
    }
  }
}

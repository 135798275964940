@import 'scss/typography';
@import 'scss/media-queries';

.container {
  color: var(--text-color-grey-1);
  background-color: var(--neutral-7);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-4) var(--space-8);

  @include until-phone {
    padding: var(--space-4);
  }

  .image {
    flex-shrink: 0;
    max-width: 48px;
    flex-basis: 20%;
    align-self: flex-end;
  }

  .contentRight {
    padding-left: var(--space-3);

    .text {
      @include label-sm;
      font-weight: var(--font-weight-semi-bold);
      font-family: var(--font-family-montserrat);
      margin-bottom: var(--space-1);
    }

    .tncLink {
      @include text-xs;
      text-decoration: underline;
      margin-bottom: var(--space-2);
    }
  }

  .ctaContainer {
    display: flex;
    gap: var(--space-3);
  }
}

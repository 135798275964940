@import 'scss/media-queries';
@import 'scss/mixins';

.dropdown {
  position: relative;
  height: 100%;
  padding-top: var(--space-3);
  padding-bottom: var(--space-3);

  // safari fix
  // fix: dropdown is opening while hovering the dropdown content event the pointer event is none
  @include from-phone {
    &.behaviour-hover {
      overflow: hidden;

      &:hover {
        overflow: visible;
      }
    }
  }
  // safari fix end

  &.behaviour-hover {
    &:hover .dropdownMenu {
      transition-delay: 150ms;
      opacity: 1;
      pointer-events: inherit;
    }
  }
}

.dropdownTrigger {
  display: flex;
  align-items: center;
  color: var(--header-nav-text-color);

  @include until-phone {
    justify-content: center;
    margin-left: var(--space-2);

    svg {
      margin: 0;
      color: var(--header-nav-text-color);
    }
  }

  &.avatar {
    padding: var(--space-2);
  }
}

.dropdownMenu {
  position: absolute;
  right: 0;
  opacity: 0;
  pointer-events: none; // ignore hover event when menu is not visible (still visibility hidden)
  will-change: opacity;
  transition: opacity 0.2s;

  &.direction-bottom {
    top: var(--space-14);
    .dropdownContent {
      border-radius: 0 0 var(--border-radius-lg) var(--border-radius-lg);
      overflow: hidden;

      &.roundedBorder {
        border-radius: var(--border-radius-lg);
      }
    }
  }

  &.direction-top {
    bottom: var(--space-10);

    .dropdownContent {
      margin-bottom: var(--space-7);
      border-radius: var(--border-radius-lg) var(--border-radius-lg) 0px 0px;
      overflow: hidden;
    }
  }

  &.isOpen {
    opacity: 1;
    pointer-events: inherit;
  }

  @include mobileHidden;
}

.dropdownContent {
  padding: var(--space-3) 0;
  background-color: var(--white);
  box-shadow: var(--shadow-bottom-2);
}

@import 'scss/mixins';
@import 'scss/typography';

.modalHeader {
  padding: var(--space-4) var(--space-6);
  border-bottom: 1px solid var(--neutral-5);

  &Search {
    display: flex;
    align-items: center;
    gap: var(--space-4);

    .closeButtonPlaceholder {
      @include size(24px);
    }

    .backButton {
      display: none;
    }
  }

  .chipContainer {
    margin-top: var(--space-4);
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-2);

    > button {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  @include until-phone {
    padding: var(--space-3) var(--space-4);

    &Search {
      gap: var(--space-2);

      .closeButtonPlaceholder {
        display: none;
      }

      .backButton {
        display: block;
        padding: 0;
        height: 24px;
        color: var(--text-color-grey-1);
      }
    }
  }
}

@import 'scss/mixins';
@import 'scss/typography';
@import 'scss/media-queries';

.destinationItem {
  padding: var(--space-3) var(--space-6);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s;
  cursor: pointer;
  max-width: 100%;
  overflow-x: hidden;

  &.active,
  &:hover {
    background-color: var(--neutral-6);

    .destinationItemArrowIcon {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &Content {
    display: flex;
    align-items: center;
    gap: var(--space-3);
  }

  &TextContainer {
    max-width: 450px;
  }

  &Text {
    @include label-md;
    color: var(--text-color-grey-1);
    text-align: left;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 450px;
  }

  &Icon {
    @include size(28px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--neutral-5);
    border-radius: 50%;
    color: var(--neutral-2);
  }

  &ArrowIcon {
    width: 32px;
    display: flex;
    align-items: center;
    opacity: 0;
    transform: translateX(-15%);
    transition: opacity 180ms ease-in-out, transform 200ms ease-in-out;
    color: var(--text-color-grey-1);
  }

  @include until-phone {
    padding: var(--space-3) var(--space-4);

    &Text {
      max-width: 60vw;
    }
  }
}

@import 'scss/media-queries';
@import 'scss/typography';
@import 'scss/mixins';

.info {
  @include label-sm-bold;
  color: var(--text-color-grey-1);
  cursor: pointer;
  padding: var(--space-4) var(--space-4);
  background-color: var(--neutral-7);
  width: 100%;
  display: grid;
  grid-template-columns: auto 24px;
  grid-gap: var(--space-3);
  align-items: center;
  position: relative;

  @include until-phone {
    width: 100vw;
  }

  &:hover,
  &:focus {
    .hoverIcon {
      opacity: 1;
      transform: translateX(0);
    }
  }

  // similar style is also available in result-card
  .hoverIcon {
    opacity: 0;
    transform: translateX(-16px);
    transition: opacity 180ms ease-in-out, transform 200ms ease-in-out;
  }

  .loadingText {
    display: grid;
    grid-template-columns: max-content min-content;

    .dots {
      width: 0;
      overflow: hidden;
      animation: loading-dots steps(3, end) 1000ms infinite;

      @keyframes loading-dots {
        from {
          width: 33%;
        }
        to {
          width: 120%;
        }
      }
    }
  }
}

.seeMoreBtn {
  padding: var(--space-4);

  @include until-phone {
    padding-top: var(--space-4);
  }

  .info {
    padding: var(--space-2) var(--space-4);
    @include overflowedText(2);
  }
  @include from-tablet {
    &.flatButton {
      padding: var(--space-2);

      .info,
      .info:hover {
        border: 0px !important;
      }
    }
  }
}

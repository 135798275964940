@import 'scss/typography';
@import 'scss/media-queries';

.settingsAppBanner {
  padding: var(--space-2) var(--space-4);
  border-radius: var(--border-radius-lg);
  display: flex;
  gap: var(--space-2);
  background-color: var(--neutral-6);
  position: relative;

  .content {
    @include label-sm;
    align-self: center;

    span {
      font-weight: var(--font-weight-bold);
    }
  }

  .qrCode {
    display: flex;
    flex-direction: column;
    gap: var(--space-1);
    align-items: center;

    svg {
      width: 88px;
      height: 88px;

      path,
      rect {
        transform: scale(0.63);
      }
    }

    .scanText {
      @include text-xs;
      color: var(--primary-variant-2);
      text-align: center;
    }
  }

  @include until-desktop {
    flex-direction: column;
    align-items: center;
    padding: var(--space-4);

    .content {
      text-align: center;
    }
  }
}

@import 'scss/typography';

.listItems {
  --setting-item-padding-v: var(--space-4);
  --setting-item-padding-h: var(--space-8);
}

.divider {
  margin: var(--space-5) 0 var(--space-4) 0;
  height: 1px;
  background: var(--text-color-grey-4);
}

.listItem {
  display: flex;
  color: var(--text-color-grey-1);
  cursor: pointer;
  position: relative;
  @include label-sm;

  &.header {
    @include label-md-bold;
    color: var(--text-color-grey-2);
    padding: var(--space-2) 0 var(--space-3) 0;
  }

  :global(.active) {
    @include label-sm-bold;
  }

  svg {
    margin-right: var(--space-2);
    flex-shrink: 0;
  }

  &:hover > * {
    background-color: var(--grey-interaction-standard);

    @include until-phone {
      background-color: transparent;
    }
  }

  &:focus-visible {
    outline: 1px solid var(--text-color);
  }

  .rightIcon {
    flex-grow: 0;
    svg {
      margin-right: 0;
    }
  }

  > * {
    display: flex;
    align-items: center;
    padding: var(--setting-item-padding-v) var(--setting-item-padding-h);
    flex-grow: 1;
  }
}

.appBanner {
  padding-top: var(--space-5);
  padding-right: var(--space-4);
  margin-top: var(--space-4);
  border-top: 1px solid var(--neutral-4);
  position: relative;
}

.new {
  @include text-xs-bold;

  color: var(--info);
  text-transform: uppercase;
}

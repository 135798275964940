@import 'scss/media-queries';
@import 'scss/mixins';
@import 'scss/typography';

.card {
  --card-width: 480px;
  --card-shadow: var(--shadow-bottom-2);
  --rounded: var(--border-radius-24);
  --padding-x: var(--space-10);

  background: var(--white);
  width: var(--card-width);
  max-width: var(--card-width);
  box-shadow: var(--card-shadow);
  border-radius: var(--rounded);
  overflow: hidden;
  position: relative;

  @include until-phone {
    --padding-x: var(--space-6);
  }

  .banner {
    padding: var(--space-12) var(--padding-x) var(--space-6);
    background: var(--neutral-7);
    display: grid;
    grid-column-gap: var(--space-2);
    grid-template-columns: 1fr 115px;
    overflow: hidden;
    
    @include until-phone {
      grid-template-columns: 1fr 90px;
    }

    .gift {
      position: relative;

      svg {
        position: absolute;
        bottom: calc(-1 * var(--space-8));
        width: 100%;
      }
    }

    h3 {
      @include header-3-bold;
    }
  }

  .content {
    padding: var(--space-8) var(--padding-x) var(--space-12);

    ul.benifits {
      display: flex;
      flex-direction: column;
      gap: var(--space-4);
      margin-bottom: var(--space-6);

      li {
        display: flex;
        gap: var(--space-2);
        color: var(--text-color-grey-1);

        p {
          @include label-md;

          @include until-phone {
            @include label-sm;
          }
        }

        svg {
          flex-shrink: 0;
        }
      }
    }

    .unsubscribeText {
      text-align: center;
      margin-top: var(--space-6);
      color: var(--text-color-grey-2);
      @include text-sm;
    }
  }

  .c &.loading {
    &::before {
      content: '';
      background: #fff;
      z-index: 10;
      opacity: 0.7;
      @include absFullSize;
      @include pulsing;
    }
  }

  @include until-phone {
    max-width: auto;
    width: 100%;
  }
}
